import useABTest from '@/services/hooks/useABTest'

export interface Experiment<T> {
  decision: { variationKey: T }
}

export type ABTestProps = Experiment<'on' | 'off'>

interface Props {
  name: string
  waitForClientReady?: boolean
  children: any
}

const ABTester: React.FC<Props> = ({
  name,
  waitForClientReady = true,
  children,
}) => {
  const [decision, clientReady] = useABTest(name)

  // Don't render the component if SDK client is not ready yet.
  if (waitForClientReady && !clientReady) {
    return null
  }

  return children({ decision })
}

export default ABTester
