import React, { useState, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import Loader from 'react-loader-spinner'
import { emailLogin } from '@/services/api/auth'
import { FilledButton, ButtonBase, Text, ErrorBox } from '@/components/common'
import AuthInput from './AuthInput'
import FacebookAuthBtn from './FacebookAuthBtn'
import GoogleAuthButton from './GoogleAuthButton'
import tailwind from '../../../tailwind.config'
import useAuth from '@/services/hooks/useAuth'
import { resolveErrorMessage, report } from '@/utils'

export default function LoginForm({ email, setEmail, setView }) {
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const { persistUser, closeModal } = useAuth()
  const [enableGoogleLogin, setEnableGoogleLogin] = useState(false)
  const router = useRouter()

  useEffect(() => {
    const { disable_google_login } = router.query
    setEnableGoogleLogin(disable_google_login !== 'true')
  }, [router.query])

  const socialPrompt = enableGoogleLogin
    ? 'If you have previously logged in with Google or Facebook, please login below.'
    : 'If you have previously logged in with Facebook, please login below.'

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault()

      try {
        setLoading(true)
        const res = await emailLogin(password, email)
        persistUser(res.data.uuid, res.data.authorization)
        closeModal()
      } catch (err) {
        report.log(err)
        setError(resolveErrorMessage(err))
      } finally {
        setLoading(false)
      }
    },
    [persistUser, closeModal, email, password]
  )

  return (
    <>
      <form
        className="flex flex-col items-center w-full"
        onSubmit={handleLogin}
      >
        <ErrorBox errorText={error} className="mb-4" />

        <AuthInput
          name="email"
          type="text"
          placeholder="EMAIL"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <AuthInput
          name="password"
          type="password"
          placeholder="PASSWORD"
          className="mt-4"
          onChange={(e) => setPassword(e.target.value)}
        />
        <FilledButton
          color="primary"
          className="rounded-full w-full flex items-center justify-center mt-4 mb-2"
          type="submit"
          disabled={loading || !email || !password}
        >
          {loading && (
            <Loader
              type="TailSpin"
              color={tailwind.theme.colors.lightGray}
              className="mr-2"
              height={25}
              width={25}
            />
          )}
          <span>Login</span>
        </FilledButton>

        <ButtonBase
          className="text-primary text-sm uppercase"
          onClick={() => setView('resetPassword')}
          disabled={loading}
          type="button"
        >
          Forgot your password?
        </ButtonBase>
      </form>

      <div className="w-full flex flex-col items-center mt-6">
        <Text as="p" preset="body.sm" className="text-gray-4 text-center">
          {socialPrompt}
        </Text>

        <FacebookAuthBtn setError={setError} />
        {enableGoogleLogin && (
          <GoogleAuthButton setError={setError} className="w-full mt-3" />
        )}
      </div>
    </>
  )
}
