import React, { useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import { emailLogin, register } from '@/services/api/auth'
import { ErrorBox, Text, FilledButton } from '@/components/common'
import useAuth from '@/services/hooks/useAuth'
import Loader from 'react-loader-spinner'
import { ImCheckmark } from 'react-icons/im'
import AuthInput from './AuthInput'
import tailwind from '../../../tailwind.config'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { resolveErrorMessage } from '@/utils'

export const VerifyCodeForm = ({ email, password }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [verified, setVerified] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const { persistUser, closeModal } = useAuth()
  const router = useRouter()

  const verifyAccount = useCallback(
    async (e) => {
      e.preventDefault()
      setError('')
      setLoading(true)

      try {
        await register(email, password, verificationCode)
        SegmentHandler.track('Auth Completed', {
          location: router.asPath,
          value: 'verifyEmail',
        })

        const res = await emailLogin(password, email)
        setVerified(true)
        persistUser(res.data.uuid, res.data.authorization)
        setTimeout(() => closeModal(), 1000)
      } catch (err) {
        setError(resolveErrorMessage(err))
      } finally {
        setLoading(false)
      }
    },
    [email, password, verificationCode, router.asPath, persistUser, closeModal]
  )

  return (
    <form className="flex flex-col items-center" onSubmit={verifyAccount}>
      <ErrorBox errorText={error} className="mb-4" />
      <Text as="p" preset="body.md" className="text-center mt-0 mb-2">
        Please enter the 6 digit code
        <br /> we sent to {email}.
      </Text>
      <Text as="p" preset="body.xs" className="text-center mt-0 mb-2">
        Not seeing it? Check your spam folder.
      </Text>

      <AuthInput
        name="code"
        type="text"
        placeholder="CODE"
        className="text-center w-4/5"
        textSizeClassName="text-lg"
        onChange={(e) => setVerificationCode(e.target.value)}
        maxLength={6}
      />

      <FilledButton
        className="w-4/5 rounded-full flex items-center justify-center mt-4"
        type="submit"
        disabled={verificationCode.length !== 6 || loading || verified}
      >
        {loading && (
          <Loader
            type="TailSpin"
            color={tailwind.theme.colors.lightGray}
            className="mr-2"
            height={25}
            width={25}
          />
        )}
        {verified && <ImCheckmark className="mr-3" />}
        <span>Verify Account</span>
      </FilledButton>
    </form>
  )
}

export default VerifyCodeForm
