import React, { useState, useCallback, useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import { useSWRConfig } from 'swr'
import { getPersistedAuth } from './utils'
import AuthContext, { AuthModalView } from './AuthContext'
import {
  setPersistedAuth,
  clearPersistedAuth,
} from '@/components/context/AuthContext/utils'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import useUser from '@/services/hooks/useUser'
import { Text, Button } from '@/components/common'

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter()
  const { cache } = useSWRConfig()
  const [userId, setUserId] = useState<string | null | undefined>(undefined)
  const [token, setToken] = useState<string | null>(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalView, setModalView] = useState<AuthModalView>('verifyAccount')
  const [redirect, setRedirect] = useState<string | null>(null)
  const { user, isError: userError } = useUser(userId)

  const openModal = useCallback(
    (view = 'verifyAccount', redirectUrl = null) => {
      setModalView(view)
      setRedirect(redirectUrl)
      setModalIsOpen(true)
    },
    []
  )

  const closeModal = useCallback(() => {
    setRedirect(null)
    setModalIsOpen(false)
  }, [])

  const clearUser = useCallback(() => {
    setUserId(null)
    clearPersistedAuth()

    // clear SWR cache. Currently no way to do this without Typescript conversion.
    // When updating to SWR v2, will use a different approach.
    // see: https://github.com/vercel/swr/issues/1887
    const c = cache as unknown as { clear: () => void }
    c.clear()

    SegmentHandler.track('Auth Completed', {
      location: router.asPath,
      value: 'logout',
    })
  }, [setUserId, router, cache])

  const persistUser = useCallback(
    (userId, token) => {
      if (!userId || !token) {
        clearUser()
        throw new Error('Unable to authenticate. Please try logging-in again.')
      }
      setUserId(userId)
      setToken(token)
      setPersistedAuth(userId, token)

      SegmentHandler.track('Auth Completed', {
        location: router.asPath,
        value: 'login',
      })
    },
    [setUserId, setToken, router, clearUser]
  )

  // Restore user on page load
  useEffect(() => {
    const { userId, token } = getPersistedAuth()
    setUserId(userId || null)
    setToken(token || null)
  }, [])

  // Check for redirect when user is logged-in
  useEffect(() => {
    if (userId && redirect) {
      Router.push(redirect)
      setRedirect(null)
    }
  }, [userId, redirect])

  if (userError) {
    return (
      <div className="h-full flex-1 flex flex-col justify-center items-center text-center p-6">
        <Text as="p" preset="body.md" className="max-w-lg">
          We encountered an error with your account. Please logout below and try
          again.
        </Text>

        <Button className="mt-4" onClick={clearUser}>
          Logout
        </Button>
      </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        modalIsOpen,
        openModal,
        closeModal,
        modalView,
        setModalView,
        persistUser,
        clearUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
