import { useState } from 'react'
import classNames from 'classnames'
import { Popover as UIPopover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import Portal from '../Portal'

export interface Props {
  children: React.ReactNode
  content: JSX.Element
  placement?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start'
  label?: string
  alwaysRender?: boolean
  theme?: 'light' | 'dark'
  className?: string
}

const Popover = ({
  children,
  content,
  placement = 'auto',
  label,
  alwaysRender = false,
  theme = 'dark',
  className,
}: Props) => {
  const [targetRef, setTargetRef] = useState<HTMLButtonElement | null>(null)
  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(targetRef, popperRef, {
    placement,
  })

  return (
    <UIPopover className={className}>
      {({ open }) => (
        <>
          <UIPopover.Button
            ref={setTargetRef}
            aria-label={label}
            className="block"
          >
            {children}
          </UIPopover.Button>

          <Portal popoverRef={targetRef}>
            <UIPopover.Panel
              static={alwaysRender}
              ref={setPopperRef}
              style={styles.popper}
              {...attributes.popper}
              className={classNames({
                'z-[9998] transition-opacity duration-150': true,
                'opacity-100 pointer-events-auto': open,
                'opacity-0 pointer-events-none': !open,
              })}
              aria-label="Popover Menu"
            >
              <div
                className={classNames('rounded max-w-[320px] m-2', {
                  'bg-white text-black shadow-section': theme === 'light',
                  'bg-gray-1 text-white': theme === 'dark',
                })}
              >
                <div className="max-h-[50vh] overflow-y-auto">{content}</div>
              </div>
            </UIPopover.Panel>
          </Portal>
        </>
      )}
    </UIPopover>
  )
}

export default Popover
