import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'
import { TriangleDown } from '@/components/svg'
import Select, { Props } from '../../Select/Select'

const FormSelect: React.FC<Props & { useNativeSelect?: boolean }> = ({
  id,
  name,
  options,
  onChange,
  useNativeSelect = false,
  className,
  ...rest
}) => {
  const { setFieldTouched } = useFormikContext()

  const onClose = useCallback(
    () => setFieldTouched(name, true),
    [name, setFieldTouched]
  )

  if (useNativeSelect) {
    return (
      <div className="w-full relative">
        <select
          {...rest}
          id={id}
          name={name}
          onChange={onChange}
          className={className}
        >
          {options.flat().map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>

        <TriangleDown
          className="absolute right-3 top-1/2 translate-y-[-50%] text-gray ml-auto shrink-0"
          style={{ width: 10, height: 5 }}
          aria-hidden="true"
        />
      </div>
    )
  }

  return (
    <Select
      {...rest}
      id={id}
      name={name}
      options={options}
      onChange={onChange}
      onClose={onClose}
      selectClassName={className}
    />
  )
}

export default FormSelect
