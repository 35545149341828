import useApi from '@/services/hooks/useApiV2'
import { getChatbotMessages } from '@/services/api/chatbot'
import { ChatbotMessage } from '@/services/api/chatbot/chatbot'

const useInvestmentChatbot = (slug: string) => {
  const { data, isLoading, isError } = useApi<ChatbotMessage[]>({
    key: `investment-chatbot/${slug}`,
    request: () => getChatbotMessages(slug),
  })

  return {
    data,
    isLoading,
    isError,
  }
}

export default useInvestmentChatbot
