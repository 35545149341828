import { Switch as TwUISwitch } from '@headlessui/react'
import classNames from 'classnames'
import useTextWidth from '@/services/hooks/useTextWidth'

const SIZES = {
  sm: {
    height: 24,
    fontSize: 10,
  },
  lg: {
    height: 30,
    fontSize: 12,
  },
}

interface Props {
  checked: boolean
  onChange: (checked: boolean) => void
  size?: 'sm' | 'lg'
  width?: number
  onText?: string
  offText?: string
  error?: boolean
  testId?: string
}

const Switch = ({
  checked,
  onChange,
  size = 'lg',
  width: overrideWidth,
  onText = '',
  offText = '',
  error = false,
  testId,
}: Props) => {
  const longestText = onText.length >= offText.length ? onText : offText
  const textWidth = useTextWidth({
    text: longestText.toUpperCase(),
    fontSize: SIZES[size].fontSize,
  })
  const height = SIZES[size].height
  const width = overrideWidth || textWidth + height + 10

  return (
    <TwUISwitch
      checked={checked}
      onChange={onChange}
      className={classNames({
        'relative inline-flex items-center rounded-full font-whitney': true,
        'bg-oxide-1': checked && !error,
        'bg-gray-8': !checked && !error,
        'bg-red': error,
      })}
      style={{
        width,
        height,
        boxShadow: checked
          ? 'inset 0px 2px 2px rgba(0, 0, 0, 0.25)'
          : 'inset 0px 2px 2px rgba(0, 0, 0, 0.1)',
      }}
      data-testid={testId}
    >
      {onText && (
        <span
          className={classNames({
            'absolute top-[50%] translate-y-[-50%] transform transition ease-in-out duration-200 focus:outline-0 leading-none':
              true,
            'text-xs': size === 'lg',
            'text-2xs': size === 'sm',
            'left-2 text-white': checked,
            'right-2 text-black': !checked && !error,
            'right-2 text-white': !checked && error,
          })}
        >
          {!checked && offText ? offText : onText}
        </span>
      )}

      <span
        className={classNames({
          'inline-block bg-white rounded-full transform transition ease-in-out duration-200':
            true,
          'bg-white border border-gray-4': !checked && !error,
          'bg-oxide-3': checked && !error,
          'bg-white border-white': error,
        })}
        style={{
          width: height - 4,
          height: height - 4,
          transform: `translateX(${checked ? width - height + 2 : 1}px)`,
          boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.25)',
        }}
      />
    </TwUISwitch>
  )
}

Switch.Label = TwUISwitch.Label
Switch.Group = TwUISwitch.Group

export default Switch
