import { createContext } from 'react'
import { ICampaignAssetsFields } from 'src/types/generated/contentful'

interface DefaultContext {
  name: string
  slug: string
}

export const CampaignAssetsContext = createContext<
  ICampaignAssetsFields | Partial<ICampaignAssetsFields>
>({
  name: '',
  slug: '',
})
interface ProviderProps {
  campaignAssets?: ICampaignAssetsFields | DefaultContext
  children: any
}

export const CampaignAssetsProvider = ({
  campaignAssets = {
    name: '',
    slug: '',
  },
  children,
}: ProviderProps) => {
  return (
    <CampaignAssetsContext.Provider value={{ ...campaignAssets }}>
      {children}
    </CampaignAssetsContext.Provider>
  )
}
