import React, { Component } from 'react'
import { report } from '@/utils'
import { Text, Button } from '@/components/common'

interface Props {
  children: React.ReactElement
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    report.error(error)
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return (
        <div className="h-full flex-1 flex flex-col justify-center items-center text-center p-6">
          <Text as="h1" preset="heading.lg" className="max-w-lg mb-4">
            Oh dear, something unexpected happened.
          </Text>

          <Text as="p" preset="body.lg" className="max-w-lg mb-6">
            Please click below to reload the page.
          </Text>

          <Button onClick={() => window.location.reload()}>
            Reload This Page
          </Button>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
