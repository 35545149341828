import Bugsnag, { NotifiableError } from '@bugsnag/js'

function error(error: NotifiableError) {
  Bugsnag.notify(error, (event) => {
    event.severity = 'error'
  })
}

function log(error: NotifiableError) {
  Bugsnag.notify(error, (event) => {
    event.severity = 'info'
  })
}

function warn(error: NotifiableError) {
  Bugsnag.notify(error, (event) => {
    event.severity = 'warning'
  })
}

export default {
  error,
  log,
  warn,
}
