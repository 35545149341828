import { useCallback, useEffect, useRef, useState } from 'react'
import { Channel, Socket } from 'phoenix'
import { ChatbotMessage } from '@/services/api/chatbot'
import { ChatbotMessagePayload } from '@/services/api/chatbot/chatbot'
import { casingUtil } from '@/utils'

enum SocketChannelTopic {
  delete_message = 'delete_message',
  funding_investment = 'funding:investment',
}

export const useSubscribeInvestmentChatbot = (
  chatCode: string,
  setMessages: React.Dispatch<React.SetStateAction<ChatbotMessage[]>>
) => {
  const [activeChannel, setActiveChannel] = useState<Channel | null>()
  const [chatSocket, setChatSocket] = useState<Socket>()
  const [chatStatus, setChatStatus] = useState<'online' | 'offline'>('offline')
  const [connectionStatus, setConnectionStatus] = useState<
    'loading' | 'connecting' | 'connected' | 'timed out' | 'error'
  >('loading')
  const [error, setError] = useState()
  const [shoutInit, setShoutInit] = useState(false)
  const chatCodeRef = useRef('')

  const connect = useCallback(() => {
    setConnectionStatus('connecting')

    const url = `${process.env.NEXT_PUBLIC_CHAT_SERVER}/socket`

    const socket = new Socket(url)
    socket.connect()

    setChatSocket(socket)

    const channel = socket.channel(`chat:${chatCode}`)

    channel
      .join()
      .receive('ok', (payload) => {
        setActiveChannel(channel)
        setConnectionStatus('connected')
        setChatStatus(payload.chat_status)
      })
      .receive('error', (error) => {
        setConnectionStatus('error')
        setError(error)
      })
      .receive('timeout', () => {
        setConnectionStatus('timed out')
      })
  }, [chatCode])

  // Cleanup
  useEffect(() => {
    return () => {
      activeChannel?.leave()
      chatSocket?.disconnect()
      setConnectionStatus('loading')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !chatCode ||
      (chatCode === chatCodeRef.current && activeChannel) ||
      connectionStatus !== 'loading'
    ) {
      return
    }

    chatCodeRef.current = chatCode
    connect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCode, activeChannel, connectionStatus])

  const updateList = useCallback(
    (messagePayload: ChatbotMessagePayload) => {
      const message = casingUtil.snakeToCamel(messagePayload)
      setMessages((currentMessages: ChatbotMessage[]) => {
        const slice = currentMessages.slice(
          Math.max(currentMessages.length - 100, 0)
        )
        return [...slice, message] as ChatbotMessage[]
      })
    },
    [setMessages]
  )

  useEffect(() => {
    if (activeChannel?.state === 'joined' && !shoutInit) {
      activeChannel?.on(SocketChannelTopic.funding_investment, updateList)

      activeChannel?.on(SocketChannelTopic.delete_message, ({ message }) => {
        setMessages((currentMessages) => {
          return [
            ...currentMessages.filter(
              (m: ChatbotMessage) => m.id !== message.id
            ),
          ]
        })
      })

      activeChannel?.on('channel_status', ({ chat_status }) => {
        setChatStatus(chat_status)
      })

      setShoutInit(true)
    }
  }, [activeChannel, shoutInit, setMessages, updateList])

  return {
    chatStatus,
    connectionStatus,
    error,
  }
}
