import * as Yup from 'yup'
import differenceInYears from 'date-fns/differenceInYears'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { formatMoney, investUtil } from '@/utils'
import { FormatMoneyOptions } from 'src/utils/formatMoney'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const phoneNumber = Yup.string()
  .matches(phoneRegExp, 'Phone number is not valid')
  .min(10, 'Phone number is not valid')
  .max(10, 'Phone number is not valid')
export const phoneNumberWithAreaCode = phoneNumber
  .min(10, 'Phone number is not valid')
  .max(10, 'Phone number is not valid')
export const internationalPhone = Yup.string().test(
  'internationalPhoneNumber',
  'Invalid phone number',
  function (value = '') {
    if (!value) return true
    return isValidPhoneNumber(value)
  }
)
export const name = Yup.string().min(2, 'Too Short!').max(50, 'Too Long!')
export const nameWithCoupleCheck = name.test(
  'probableCoupleInvestment',
  'Investing as a couple is not currently allowed',
  (name = '') => !/(&| and )/.test(name.toLowerCase())
)
const email = Yup.string().email('Invalid email')
export const simpleString = Yup.string()
  .min(2, 'Too Short!')
  .max(50, 'Too Long!')
export const city = simpleString.matches(
  /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'|\.))*[a-zA-Z\u0080-\u024F]*$/,
  `Must be letters`
)
export const postalCode = Yup.string()
  .matches(/^[0-9]+$/, 'Must be only digits')
  .min(5, 'Must be exactly 5 digits')
  .max(5, 'Must be exactly 5 digits')
const bool = Yup.boolean()

export const creditCard = Yup.object({
  fullName: name.required('Required'),
  cardNumber: Yup.string()
    .required('Required')
    .matches(/^[0-9]+$/, 'Must be digits')
    .min(16, 'Must be exactly 16 digits')
    .max(16, 'Must be exactly 16 digits')
    .test('luhn_check', 'Invalid card number', (cardNumber) => {
      if (!cardNumber) return false
      let nCheck = 0
      let bEven = false
      for (let n = cardNumber.length - 1; n >= 0; n--) {
        let nDigit = parseInt(cardNumber.charAt(n), 10)
        if (bEven) {
          if ((nDigit *= 2) > 9) nDigit -= 9
        }
        nCheck += nDigit
        bEven = !bEven
      }
      return nCheck % 10 === 0
    }),
  monthYear: Yup.string()
    .required('Required')
    .typeError('Invalid expiry')
    .max(5, 'Invalid expiry')
    .matches(/([0-9]{2})\/([0-9]{2})/, 'Invalid expiry')
    .test(
      'test-credit-card-expiration-date',
      'Must be future date',
      (expirationDate) => {
        if (!expirationDate) {
          return false
        }

        const today = new Date()
        const monthToday = today.getMonth() + 1
        const yearToday = today.getFullYear().toString().substr(-2)

        const [expMonth, expYear] = expirationDate.split('/')

        if (Number(expYear) < Number(yearToday)) {
          return false
        } else if (
          Number(expMonth) < monthToday &&
          Number(expYear) <= Number(yearToday)
        ) {
          return false
        }

        return true
      }
    )
    .test(
      'test-credit-card-expiration-date',
      'Invalid month',
      (expirationDate) => {
        if (!expirationDate) {
          return false
        }
        const [expMonth] = expirationDate.split('/')
        return Number(expMonth) <= 12
      }
    ),
  cvc: Yup.string()
    .required('Required')
    .matches(/^[0-9]+$/)
    .min(3, 'Must be exactly 3 digits')
    .max(3, 'Must be exactly 3 digits'),
})

export const plaid = Yup.object({
  plaidAccountId: Yup.string().required(),
  plaidToken: Yup.string().required(),
})

export const ach = Yup.object({
  routingNumber: Yup.string()
    .required('Required')
    .matches(/^[0-9]+$/)
    .min(8, 'Must be at least 8 digits')
    .max(9, 'Must be no more than 9 digits'),
  accountNumber: Yup.string()
    .required('Required')
    .matches(/^[0-9]+$/)
    .min(6, 'Must be at least 6 digits')
    .max(17, 'Must be no more than 17 digits'),
  accountType: Yup.string().required('Required'),
})

export const birthday = simpleString
  .required('Required')
  .test('test_valid_date', 'Invalid Date', (birthday) => {
    return birthday?.replace(/\D/g, '').length === 8
  })
  .test('test_valid_month', 'Invalid Month', (birthday) => {
    if (birthday === undefined) return false
    const month = parseFloat(birthday?.split('-')[0])
    return month <= 12 && month > 0
  })
  .test('test_valid_day', 'Invalid Day', (birthday) => {
    if (birthday === undefined) return false
    const day = parseFloat(birthday?.split('-')[1])
    return day <= 31 && day > 0
  })
  .test('test_valid_year', 'Invalid Year', (birthday) => {
    if (birthday === undefined) return false
    const year = parseFloat(birthday?.split('-')[2])
    const current_year = new Date().getUTCFullYear()
    return year > 1900 && year < current_year
  })
  .test('test_old_enough', 'Must be 18 years old', (birthday = '') => {
    const [month, day, year] = birthday
      .split('-')
      .map((value) => parseInt(value, 10))
    const bday = new Date(year, month - 1, day)
    const today = new Date()
    const diffYears = differenceInYears(today, bday)
    return diffYears >= 18
  })

// Note: this is currently a thin-mint file-size limitation
// This may need to be extended to allow other file size limits
// in the future
export const FILE_SIZE_LIMIT = 5 * 1024 * 1024

export const files = Yup.array()
  .of(Yup.mixed())
  .test(
    'fileSize',
    `Files must be less than ${FILE_SIZE_LIMIT / 1024 / 1024}MB`,
    (files) => {
      if (!files) return true
      return files.every(({ file, size }) =>
        file ? file.size < FILE_SIZE_LIMIT : size < FILE_SIZE_LIMIT
      )
    }
  )

export const singleFile = files.max(1, 'Only one file allowed')

export const ssn = Yup.string()
  .matches(/^[0-9]+$/, 'Must be digits')
  .min(9, 'Must be exactly 9 digits')
  .max(9, 'Must be exactly 9 digits')

export const investEntity = Yup.object({
  id: Yup.string(), // Not required because you might be saving new entity
  accountId: Yup.string().nullable(), // Same as above
  name: name.required('Required'),
  entityType: Yup.string().required('Required'),
  email: email.required('Required'),
  phoneNumber: internationalPhone.required('Required'),
  address1: simpleString.required('Required'),
  address2: simpleString,
  city: city.required('Required'),
  state: simpleString.required('Required'),
  postalCode: postalCode.required('Required'),
  hasEin: bool,
  requiresEin: bool,
  ein: ssn.when(['id', 'hasEin', 'requiresEin'], {
    is: (id: string, hasEin: boolean, requiresEin: boolean) =>
      !!id && !hasEin && requiresEin,
    then: ssn.required('Required'),
  }),
  documents: Yup.array().when('id', {
    is: (val: string) => !!val,
    then: Yup.array()
      .of(Yup.mixed())
      .min(
        1,
        'Please upload at least one document containing the requested information'
      ),
  }),
})

export const infoSchema = Yup.object().shape({
  firstName: nameWithCoupleCheck.required('Required'),
  lastName: nameWithCoupleCheck.required('Required'),
  email: email.required('Required'),
  phoneNumber: internationalPhone.required('Required'),
  birthday,
  address1: simpleString.required('Required'),
  address2: simpleString,
  country: simpleString.required('Required'),
  city: city.required('Required'),
  state: simpleString.when('country', {
    is: 'US',
    then: simpleString.required('Required'),
  }),
  postalCode: postalCode.when('country', {
    is: 'US',
    then: postalCode.required('Required'),
  }),
  showSsn: bool,
  ssn: ssn.when(['showSsn', 'country'], {
    is: (showSsn: boolean, country: string) => showSsn && country === 'US',
    then: ssn.required('Required'),
  }),
  accredited: bool,
  isEntity: bool,
  entity: Yup.object().when('isEntity', {
    is: true,
    then: investEntity,
  }),
})

const formatOpts: FormatMoneyOptions = {
  zeroFractionDigits: true,
  isCents: false,
}

const incomeNetWorth = Yup.number().test('range', function () {
  // `this.parent` holds all values at the current level of schema, i.e schema.meta
  const { yearlyIncome, netWorth, otherInvestmentsPastYear, accredited } =
    this.parent

  // we can look up values at higher levels in the schema in the `this.from` array
  const { additionalInvestmentAmount = 0, minimum = 0, regulationType } =
    // 'this.from' attribute isn't typed by library yet
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.from?.[1]?.value || {}

  // no validation necessary if accredited or A TIER-1
  if (accredited || regulationType === 'A TIER-1') return true

  const otherInvestments =
    regulationType === 'CF' ? otherInvestmentsPastYear : 0

  const limit = investUtil.getInvestLimit(
    investUtil.castNumber(yearlyIncome),
    investUtil.castNumber(netWorth),
    investUtil.castNumber(additionalInvestmentAmount) +
      investUtil.castNumber(otherInvestments),
    regulationType
  )

  const minString = formatMoney(minimum, formatOpts)

  if (minimum > limit) {
    return this.createError({
      message: `Your investment limit is lower than the minimum amount you can invest in this campaign (${minString}).`,
    })
  }

  return true
})

export const userInvestmentMeta = Yup.object({
  yearlyIncome: incomeNetWorth
    .when(['regulationType', 'accredited'], {
      is: (regulationType: string, accredited: boolean) =>
        regulationType !== 'CF' && !accredited,
      then: incomeNetWorth.required('Required'),
    })
    .min(0, 'Income must be a positive number')
    .integer('Please provide a whole number'),
  netWorth: incomeNetWorth
    .when(['regulationType', 'accredited'], {
      is: (regulationType: string, accredited: boolean) =>
        regulationType !== 'CF' && !accredited,
      then: incomeNetWorth.required('Required'),
    })
    .min(0, 'Income must be a positive number')
    .integer('Please provide a whole number'),
  otherInvestmentsPastYear: Yup.number()
    .min(0, 'Income must be a positive number')
    .integer('Please provide a whole number'),
})

export const amountSchema = Yup.object().shape({
  entityType: Yup.string(),
  regulationType: Yup.string(),
  minimum: Yup.number(),
  maximum: Yup.number(),
  additionalInvestmentAmount: Yup.number(),
  accredited: bool,
  meta: userInvestmentMeta,
  amountToInvest: Yup.number()
    .required('required')
    .test('range', function (value) {
      const otherInvestmentsPastYear =
        this.parent.regulationType === 'CF'
          ? this.parent.meta.otherInvestmentsPastYear
          : 0

      const limit = investUtil.getInvestLimit(
        investUtil.castNumber(this.parent.meta.yearlyIncome),
        investUtil.castNumber(this.parent.meta.netWorth),
        investUtil.castNumber(this.parent.additionalInvestmentAmount) +
          investUtil.castNumber(otherInvestmentsPastYear),
        this.parent.regulationType
      )

      const unlimited =
        this.parent.accredited || this.parent.regulationType === 'A TIER-1'

      const minString = formatMoney(this.parent.minimum, formatOpts)
      const maxString = formatMoney(this.parent.maximum, formatOpts)
      const limitString = formatMoney(limit, formatOpts)

      // Below minimum
      if (typeof value === 'number' && value < this.parent.minimum) {
        if (this.parent.entityType) {
          return this.createError({
            message: `The minimum investment amount for ${this.parent.entityType}s is ${minString}`,
          })
        }

        return this.createError({
          message: `The minimum investment amount for this offering is ${minString}`,
        })
      }

      // Above limit
      if (
        !unlimited &&
        typeof value === 'number' &&
        limit >= this.parent.minimum && // if limit less than min, error will be thrown on income/netWorth fields
        value > limit
      ) {
        return this.createError({
          message: `You can't invest more than your investment limit (${limitString}). Please double-check your financial information.`,
        })
      }

      // Above maximum
      if (typeof value === 'number' && value > this.parent.maximum) {
        return this.createError({
          message: `The maximum investment amount for this offering is ${maxString}`,
        })
      }

      return true
    }),
})

export const payment = {
  paymentType: Yup.string()
    .required('Please select a payment method')
    .test(
      'validPaymentType',
      'Please select a payment method',
      (paymentType = '') =>
        ['credit_card', 'ach', 'plaid', 'wire'].includes(paymentType)
    ),
  editingPaymentType: Yup.string(),
  payment: Yup.object()
    .when(['paymentType', 'editingPaymentType'], {
      is: (paymentType: string, editingPaymentType: string) =>
        paymentType === 'credit_card' || editingPaymentType === 'credit_card',
      then: creditCard,
    })
    .when(['paymentType', 'editingPaymentType'], {
      is: (paymentType: string, editingPaymentType: string) =>
        paymentType === 'plaid' || editingPaymentType === 'plaid',
      then: plaid,
    })
    .when(['paymentType', 'editingPaymentType'], {
      is: (paymentType: string, editingPaymentType: string) =>
        paymentType === 'ach' || editingPaymentType === 'ach',
      then: ach,
    }),
}

export const paymentSchema = Yup.object(payment)

export const profileSchema = Yup.object({
  firstName: name,
  lastName: name,
  dialCode: Yup.string(),
  phone: internationalPhone.required('Required'),
  country: simpleString,
  state: simpleString,
  displayAnonymous: bool,
})
