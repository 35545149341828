import { AngelRoundIcon } from '@/components/svg'
import { ChatbotMessage } from '@/services/api/chatbot'
import { formatMoney } from '@/utils'
import { Transition } from '@headlessui/react'
import { format } from 'date-fns'
import React from 'react'
import Text from '../Text/Text'

interface Props {
  message: ChatbotMessage
}

const Message: React.FC<Props> = ({ message }) => {
  const investmentDate =
    typeof message.completed === 'number'
      ? new Date(message.completed * 1000)
      : null

  return (
    <Transition
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      appear
      show
    >
      <Text
        as="p"
        preset="body.md"
        className="mb-4 xtransition-opacity duration-500 flex items-center text-gray-3"
      >
        <AngelRoundIcon className="mr-1 text-gray-1 flex-shrink-0" />
        <span>
          {message.firstName === 'Anonymous' ? 'Someone' : message.firstName}{' '}
          from {message.state} invested{' '}
          {formatMoney(message.amount, {
            zeroFractionDigits: true,
          })}
          !{' '}
          {investmentDate && (
            <span className="text-gray-7 whitespace-nowrap">
              {format(investmentDate, 'Pp')}
            </span>
          )}
        </span>
      </Text>
    </Transition>
  )
}

export default Message
