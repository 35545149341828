import classnames from 'classnames'
import React from 'react'
import { Text } from '@/components/common'

interface Props {
  className?: string
  label: string
  stat: string
}

const Callout: React.FC<Props> = ({ className, label, stat }) => {
  return (
    <div
      className={classnames(
        'bg-gray-9 flex-[0_0_100%] md:flex-auto mb-2 last:mb-0 md:mb-0 p-1.5 rounded-lg text-center',
        className
      )}
    >
      <Text
        as="p"
        preset="custom"
        className="text-lg md:text-xs leading-normal tracking-none"
      >
        {stat}
      </Text>
      <Text
        as="p"
        preset="custom"
        className="text-xs md:text-2xs leading-normal tracking-none"
      >
        {label}
      </Text>
    </div>
  )
}

export default Callout
