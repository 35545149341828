import axios from 'axios'
import client from '../thinMintClient'

export interface User {
  id: string
  email: string
  first_name: string
  last_name: string
  display_name: string
  phone: string
  verified_phone: string
  stats_id: string
  invite_url_key: string
  is_developer: boolean
  is_beta_user: boolean
  is_preview_user: boolean
  is_superuser: boolean
  last_login: string
  date_joined: string
  stripe_customer_id: string
  purchase_pin: string
  metabase_dashboards: {
    id: string
    name: string
  }[]
  public_preferences: { [key: string]: boolean }
  related_campaigns: string[]
  agreed_to_investor_terms: string
  signup_location: string
}

const baseEndpoint = '/users'

export const updateFinancialTies = (
  related_campaigns: string[],
  userId: string
) => {
  return client.patch<User>(`${baseEndpoint}/${userId}/`, { related_campaigns })
}

export const updateUserName = (
  userId: string,
  first_name: string,
  last_name: string
) => {
  return client.patch<User>(`${baseEndpoint}/${userId}/`, {
    first_name,
    last_name,
  })
}

export const updateUserAgreedTerms = (userId: string) => {
  return client.post<User>(`${baseEndpoint}/${userId}/agree_to_investor_terms/`)
}

export const initializeInvestmentTestData = (userId: string) => {
  return client.post<Record<string, unknown>>(
    `${baseEndpoint}/${userId}/generate-test-investments/`
  )
}

export const getUser = (userId: string) => {
  return client.get<User>(`${baseEndpoint}/${userId}/`)
}

export const verifyAccountExists = async (email: string) => {
  try {
    const response = await client.post(`${baseEndpoint}/exists/`, { email })
    // 200 is expected if account exists
    return response?.status === 200
  } catch (error) {
    // 404 is expected if account doesn't exist
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return false
    }
    throw error
  }
}

export default {
  baseEndpoint,
  getUser,
  updateFinancialTies,
  updateUserName,
  updateUserAgreedTerms,
  initializeInvestmentTestData,
  verifyAccountExists,
}
