import { Content } from '@/services/contentful/types'

export interface MappedContent {
  id: string
  contentType: string
  [key: string]: any
}

export function getAllByContentType(content: Content[], contentType: string) {
  return content.filter(
    (entry: Content) => entry.sys.contentType.sys.id === contentType
  )
}

export function getByContentType(content: Content[], contentType: string) {
  return content.find(
    (entry: Content) => entry.sys.contentType.sys.id === contentType
  )
}

export function mapContent(
  content: Content | undefined
): MappedContent | undefined {
  if (!content) return undefined

  return {
    id: content.sys.id,
    contentType: content.sys?.contentType?.sys?.id,
    ...content.fields,
  }
}

export function mapContentRecursive(
  content: Content | undefined
): Content | MappedContent | null {
  if (!content) return null // JSON serialization will error if undefined...

  // if fields, content needs to be mapped
  if (content.fields) {
    return {
      id: content.sys.id,
      contentType: content.sys.contentType?.sys?.id || '',
      ...Object.entries(content.fields).reduce((acc, [key, val]) => {
        return {
          ...acc,
          [key]: Array.isArray(val)
            ? val.map(mapContentRecursive)
            : mapContentRecursive(val),
        }
      }, {}),
    }
  }

  // if no fields, return as-is
  return content
}
