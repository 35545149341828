import React from 'react'
import Popover, { Props as PopoverProps } from './Popover'
import { Question } from '@/components/svg'

interface Props extends Omit<PopoverProps, 'children'> {
  content: JSX.Element
}

const InfoPopover = ({ content, ...rest }: Props) => (
  <Popover {...rest} content={<div className="p-4">{content}</div>}>
    <Question className="text-oxide-1 relative h-[1em] top-[.1em]" />
  </Popover>
)

export default InfoPopover
