import classnames from 'classnames'
import NextImage from 'next/image'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'

import ButtonBase from '../ButtonBase'
import ImageLoader from '../ImageLoader'
import Text from '../Text/Text'
import Image from '@/components/contentful/Image'
import { Campaign as ICampaign } from '@/services/api/campaign'
import { getCampaignAssets } from '@/services/api/campaignAssets'
import useApi from '@/services/hooks/useApiV2'
import { formatMoney, progressUtil } from '@/utils'
import {
  ICampaignAssets,
  ICampaignAssetsFields,
} from 'src/types/generated/contentful'
import Callout from './Callout'
import SellingFast from '@/components/pages/CampaignPages/InvestComponents/SellingFast'
import { FormatMoneyOptions } from 'src/utils/formatMoney'

interface Props {
  campaign: ICampaign
  className?: string
  noDescription?: boolean
}

const MONEY_FORMAT: FormatMoneyOptions = {
  zeroFractionDigits: true,
}

const VerticalCampaignCard: React.FC<Props> = ({
  campaign,
  className,
  noDescription,
}) => {
  const [assets, setAssets] = useState<ICampaignAssetsFields | undefined>()
  const isTestingTheWaters = campaign.regulationType === 'testing_the_waters'
  const { data, isLoading } = useApi<{ campaignAssets: ICampaignAssets }>(
    getCampaignAssets({ slug: campaign.slug })
  )
  const clarificationSymbol = campaign.isSellingFast === true ? '†' : ''

  useEffect(() => {
    if (!data?.campaignAssets?.fields) return

    setAssets(data.campaignAssets.fields)
  }, [data])

  return (
    <div
      className={classnames(
        'flex flex-col shadow shadow-gray-8 rounded-lg w-full overflow-hidden',
        className
      )}
    >
      {isLoading && <ImageLoader />}
      {!isLoading && assets?.image && (
        <Image image={assets.image} width={327} height={184} fit="fill" lazy />
      )}
      {!isLoading && !assets?.image && campaign.previewImg && (
        <NextImage
          src={campaign.previewImg}
          alt={`${campaign.name} hero`}
          height={184}
          width={327}
          loading="lazy"
          objectFit="cover"
        />
      )}
      <div className="pt-4 pb-5 px-6 md:pt-2 md:pb-4 md:px-4 text-left">
        <Text
          as="h4"
          preset="custom"
          className="text-xl md:text-lg leading-normal tracking-tighter"
        >
          {campaign.name}
        </Text>
        {assets?.subtitle && (
          <Text
            as="p"
            preset="custom"
            className="mb-1 leading-tight text-base tracking-none md:text-xs md:tracking-none md:leading-tight"
          >
            {assets.subtitle}
          </Text>
        )}
        <Text
          as="p"
          preset="custom"
          className="text-sm md:text-2xs leading-normal tracking-tighter mb-4 capitalize"
        >
          {`${campaign.type} | Offering: ${campaign.offeringModel}`}
        </Text>
        {campaign.shortAbout && !noDescription && (
          <Text as="p" preset="body.md">
            {campaign.shortAbout}
          </Text>
        )}
        {!isTestingTheWaters && (
          <div className="mb-4">
            {campaign.capitalRaised >= campaign.minimumCapitalGoal &&
              campaign.stretchGoal && (
                <Text as="p" preset="body.xs" className="font-semibold mb-2">
                  {campaign.stretchGoal.title}
                </Text>
              )}
            <div className="w-full bg-gray-8 rounded-lg h-3 mb-1">
              <div
                className="h-full bg-oxide-800 rounded-lg"
                style={{
                  // needs min, or it looks bad with rounded corners
                  minWidth: 8,
                  width: progressUtil.getProgressWidth(campaign.progress),
                }}
              />
            </div>
            <Text as="p" preset="body.sm">
              <Text
                as="span"
                preset="custom"
                className="text-lg md:text-base leading-normal tracking-none"
              >
                {formatMoney(campaign.amountRaised, MONEY_FORMAT)}
              </Text>{' '}
              {campaign.progressWithGoalString}
            </Text>
          </div>
        )}
        {/* Pledged and Backers row */}
        {isTestingTheWaters ? (
          <div className="flex flex-row flex-wrap">
            <Callout
              className="md:mr-2"
              stat={formatMoney(campaign.amountRaised, MONEY_FORMAT)}
              label="Interest Expressed"
            />
            <Callout
              stat={Number(campaign.backers).toLocaleString()}
              label="Backers"
            />
          </div>
        ) : (
          <div className="flex flex-wrap">
            <Callout
              className="md:mr-2"
              stat={Number(campaign.backers).toLocaleString()}
              label="Backers"
            />
            <Callout
              stat={Number(
                campaign.timeRemaining.timeRemainingValue
              ).toLocaleString()}
              label={`${campaign.timeRemaining.timeRemainingDimension} left ${clarificationSymbol}`}
            />
            <SellingFast
              campaign={campaign}
              className="my-2 text-black"
              showHeading={false}
            />
          </div>
        )}
      </div>

      <Link href={`/${campaign.slug}`}>
        <a className="mt-auto">
          <ButtonBase
            className="bg-oxide-800 duration-150 h-12 leading-tight px-6 text-white tracking-wide transition-colors uppercase w-full lg:text-xs hover:bg-oxide-1"
            tabIndex={-1}
          >
            {isTestingTheWaters ? 'Express Interest' : 'Back this Project'}
          </ButtonBase>
        </a>
      </Link>
    </div>
  )
}

export default VerticalCampaignCard
