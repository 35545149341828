import { Fire } from '@/components/svg'

const SellingFast = ({ campaign, className, showHeading = true }) => {
  if (!campaign.isSellingFast) {
    return null
  }

  return (
    <div className={className}>
      {showHeading && (
        <div className="capitalize font-semibold flex-row flex items-center">
          <Fire color="#D50000" size={20} />
          <span className="text-lg ml-1">SELLING FAST</span>
        </div>
      )}
      <div className="text-xs">
        †Estimated time left until the offering is full based on current volume
        of investments.
      </div>
    </div>
  )
}

export default SellingFast
