import React from 'react'
import { ButtonBase } from '@/components/common'

interface Props {
  label: string
  showItem: boolean
  onClick: () => void
}

const MenuItem: React.FC<Props> = ({ label, showItem, onClick }) => {
  if (!showItem) return null

  return (
    <ButtonBase className="p-2 mx-2" onClick={onClick}>
      {label}
    </ButtonBase>
  )
}

export default MenuItem
