import React from 'react'
import classNames from 'classnames'
import ButtonBase, { Props as BaseProps } from '../ButtonBase/ButtonBase'
import config from './config'

export type Variant = keyof typeof config

export type Color<T extends Variant> = keyof typeof config[T]

interface CommonProps extends Omit<BaseProps, 'styled'> {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  rounded?: boolean
  disabled?: boolean
}

export interface VariantButton<T extends Variant> extends CommonProps {
  variant?: T
  color?: T extends Variant ? Color<T> : never
}

export type Props =
  | VariantButton<'primary'>
  | VariantButton<'secondary'>
  | VariantButton<'text'>

const Button: React.FC<Props> = ({
  variant = 'primary',
  color = 'oxide',
  size = 'md',
  rounded = true,
  disabled = false,
  className,
  ...rest
}) => {
  // TODO: typescript warning about invalid variant/color combos
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const colorClassName = config[variant]?.[color]

  return (
    <ButtonBase
      {...rest}
      styled
      className={classNames(
        {
          'focus:outline-none focus-visible:ring-1 leading-tight': true,
          'uppercase tracking-wide': size !== 'xs' && size !== 'sm',
          'disabled:pointer-events-none transition-all duration-150': true,
          'h-16 px-8': size === 'lg',
          'h-12 px-6': size === 'md',
          'h-8 px-3': size === 'sm',
          'h-6 px-2 text-sm': size === 'xs',
          rounded,
        },
        [className, colorClassName]
      )}
      disabled={disabled}
    />
  )
}

export default Button
