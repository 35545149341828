import { Investment } from '@/services/api/investment/types'
import { Campaign } from '@/services/api/campaign'

export const FINRA_THRESHOLD = 124000
export const ENTITY_MINIMUM = 3500
export const NON_ACCREDITED_CF_BASE_LIMIT = 2500
export const NON_ACCREDITED_CF_MAX_LIMIT = 123500

export const createDate = (days: number, months: number, years: number) => {
  const date = new Date()
  date.setDate(date.getDate() + days)
  date.setMonth(date.getMonth() + months)
  date.setFullYear(date.getFullYear() + years)
  return date
}

export const getSiblingInvestmentAmount = (
  investments: Investment[],
  campaign: Campaign
) => {
  const filteredInvestments = investments.filter(
    (investment) =>
      investment?.campaign === campaign.slug &&
      investment?.completed &&
      !investment?.canceled
  )

  const sum = filteredInvestments.reduce(
    (sum, investment) => sum + investment.amount,
    0
  )
  return sum / 100
}

export const getCFInvestmentsInPastYearAmount = (investments: Investment[]) => {
  const filteredInvestments = investments.filter((investment) => {
    const yearAgo = createDate(0, 0, -1)
    const createAtDate = new Date(investment.created)

    return (
      investment?.regulationType === 'CF' &&
      investment?.completed &&
      !investment?.canceled &&
      createAtDate > yearAgo
    )
  })

  const sum = filteredInvestments.reduce(
    (sum, investment) => sum + investment.amount,
    0
  )
  return sum / 100
}

// - If users' net worth or income is below the threshold established by FINRA(currently $107,000), Users can only invest up to 5 percent of the higher amount between their income/net worth or $2,200(whichever is highest).
// - If users net worth and income is higher than the threshold, they can invest up to 10 percent of their net worth or income(whichever is highest) up to the finra threshold.
// - If users fall into the accredited investors category, there are no limits.
export const getBaseInvestLimit = (
  yearlyIncome = 0,
  netWorth = 0,
  regulationType: string
) => {
  const maxBetweenIncomeAndNetWorth = Math.max(yearlyIncome, netWorth)

  if (regulationType !== 'CF') {
    return maxBetweenIncomeAndNetWorth * 0.1
  }

  if (yearlyIncome >= FINRA_THRESHOLD && netWorth >= FINRA_THRESHOLD) {
    return Math.min(
      maxBetweenIncomeAndNetWorth * 0.1,
      NON_ACCREDITED_CF_MAX_LIMIT
    )
  } else {
    const limit = Math.max(
      maxBetweenIncomeAndNetWorth * 0.05,
      NON_ACCREDITED_CF_BASE_LIMIT
    )
    return Math.min(limit, NON_ACCREDITED_CF_MAX_LIMIT)
  }
}

export const getInvestLimit = (
  yearlyIncome: number,
  netWorth: number,
  additionalInvestmentAmount: number,
  regulationType: string
) => {
  const baseLimit = getBaseInvestLimit(yearlyIncome, netWorth, regulationType)
  const investmentLimit = baseLimit - additionalInvestmentAmount
  return Math.max(0, investmentLimit)
}

export const calculateSharesAndExactAmount = (
  amount: number,
  sharePrice: number
) => {
  let numberOfShares = Math.floor(amount / sharePrice)
  let exactAmountToInvest = numberOfShares * sharePrice

  if (exactAmountToInvest < 10000) {
    numberOfShares = Math.ceil(amount / sharePrice)
    exactAmountToInvest = numberOfShares * sharePrice
  }

  return {
    numberOfShares,
    exactAmountToInvest,
  }
}

export const castNumber = (val: number | string) => {
  if (typeof val === 'number') {
    return val
  }

  const formatted = parseFloat(val)
  return isNaN(formatted) ? 0 : formatted
}

export const getAmountThreshold = (regulationType: string) => {
  if (regulationType === 'CF') return 999
  return 0
}
