import React from 'react'
import classNames from 'classnames'

export default function LoginForm({
  className = '',
  textSizeClassName = 'text-base',
  ...rest
}) {
  return (
    <input
      className={classNames(
        {
          'focus:outline-none appearance-none': true,
          'block w-full h-12 px-4': true,
          'bg-white border border-gray-5 rounded-full': true,
          [`${textSizeClassName} text-black leading-normal`]: true,
        },
        className
      )}
      {...rest}
    />
  )
}
