import { ChatbotMessage } from '@/services/api/chatbot'

const MAX_MS_WAIT = 3000
const MIN_MS_WAIT = 500

export const addMessageRandomInterval = (
  messages: ChatbotMessage[],
  setMessages: (messages: ChatbotMessage[]) => void
) => {
  let i = 0
  let tempMessages: ChatbotMessage[] = []

  if (!messages) return

  function addMessage() {
    if (i < messages.length) {
      // random ms between min and max values
      const randMs = Math.random() * (MAX_MS_WAIT - MIN_MS_WAIT) + MIN_MS_WAIT
      setTimeout(() => {
        tempMessages = [...tempMessages, messages[i]]
        setMessages(tempMessages)
        i++
        addMessage()
      }, randMs)
    }
  }
  addMessage()
}

export const scrollToBottomOfElement = (element: HTMLDivElement) => {
  element.scrollTo({
    top: element.scrollHeight,
    behavior: 'smooth',
  })
}
