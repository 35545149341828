import useApi from '@/services/hooks/useApiV2'
import { getIssuers, Issuer } from '@/services/api/issuer/issuer'

const useIssuers = () => {
  const { data, isLoading, isError } = useApi<Issuer[]>({
    key: `issuers`,
    request: () => getIssuers(),
  })

  return {
    issuers: data,
    isLoading,
    isError,
  }
}

export default useIssuers
