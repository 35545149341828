import { createContext } from 'react'
import { User } from '@/services/api/user/user'
import { getDefaultContext } from './utils'

export type AuthModalView =
  | 'register'
  | 'login'
  | 'resetPassword'
  | 'verifyAccount'
  | 'verifyEmail'

export interface Context {
  user?: User | null
  token: string | null
  openModal: (view?: AuthModalView, redirect?: string) => void
  closeModal: () => void
  modalIsOpen: boolean
  modalView: AuthModalView
  setModalView: (view: AuthModalView) => void
  persistUser: (userId: string, token: string) => void
  clearUser: () => void
}

const defaultContext = getDefaultContext()

const AuthContext = createContext<Context>(defaultContext as Context)

export default AuthContext
