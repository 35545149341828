import React, { useCallback } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { SiFacebook } from 'react-icons/si'
import { facebookLogin } from '@/services/api/auth'
import { Button } from '@/components/common'
import useAuth from '@/services/hooks/useAuth'
import { resolveErrorMessage, report } from '@/utils'

const FacebookAuthBtn = ({ setError }) => {
  const { persistUser, closeModal } = useAuth()

  const reqState = Math.ceil(Math.random() * 1000000000000)
  const redirect = encodeURI(
    `${location.protocol}//${location.host}?state=${reqState}`
  )

  const callback = useCallback(
    async (resp) => {
      setError('')

      try {
        const res = await facebookLogin(reqState, resp.accessToken)
        persistUser(res.data.uuid, res.data.authorization)
        closeModal()
      } catch (err) {
        report.error(err)
        setError(resolveErrorMessage(err))
      }
    },
    [reqState, setError, persistUser, closeModal]
  )

  return (
    <FacebookLogin
      appId="259105908812835"
      redirectUri={redirect}
      scope="public_profile, email"
      state={reqState}
      disableMobileRedirect={true}
      callback={callback}
      render={(renderProps) => (
        <Button
          className="w-full mt-3"
          size="sm"
          color="gray"
          onClick={renderProps.onClick}
        >
          <div className="flex flex-row items-center justify-center w-full relative">
            <SiFacebook className="text-md mr-2" />
            <span>Login with Facebook</span>
          </div>
        </Button>
      )}
    />
  )
}
export default FacebookAuthBtn
