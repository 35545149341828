import React from 'react'
import classNames from 'classnames'
import Text from '../Text/Text'

interface Props {
  error: string | null
  className?: string
}

const FormError: React.FC<Props> = ({ error, className = '' }) => {
  if (error) {
    return (
      <Text
        as="div"
        preset="body.sm"
        className={classNames('text-red mt-1', className)}
      >
        {error}
      </Text>
    )
  }

  return null
}

export default FormError
