export const ANGEL_USERID = '@ANGEL_USERID'
export const ANGEL_AUTH_TOKEN = '@ANGEL_AUTH_TOKEN'

export const setPersistedAuth = (userId: string, token: string) => {
  localStorage.setItem(ANGEL_USERID, userId)
  localStorage.setItem(ANGEL_AUTH_TOKEN, token)
}

export const getPersistedAuth = () => {
  if (typeof window === 'undefined') return { userId: null, token: null }

  return {
    userId: localStorage.getItem(ANGEL_USERID),
    token: localStorage.getItem(ANGEL_AUTH_TOKEN),
  }
}

export const clearPersistedAuth = () => {
  localStorage.removeItem(ANGEL_USERID)
  localStorage.removeItem(ANGEL_AUTH_TOKEN)
}

export const getDefaultContext = () => ({
  user: undefined,
  token: null,
  openModal: () => null,
  closeModal: () => null,
  modalIsOpen: false,
  modalView: 'verifyAccount',
  setModalView: () => null,
  persistUser: () => null,
  clearUser: () => null,
})
