import CardModal from '@/components/common/CardModal'
import Markdown from '@/components/Markdown'

interface Props {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  heading?: string | undefined
  info: string
}

export default function MarkdownModal({
  isOpen,
  setIsOpen,
  heading,
  info,
}: Props) {
  return (
    <CardModal isOpen={isOpen} setIsOpen={() => setIsOpen(false)}>
      <div className="max-w-prose">
        {heading && <CardModal.Header>{heading}</CardModal.Header>}
        <CardModal.Body>
          <Markdown source={info} />
        </CardModal.Body>
        <CardModal.Footer></CardModal.Footer>
      </div>
    </CardModal>
  )
}
