import React from 'react'
import ButtonBase from '../ButtonBase'
import { PlusCircle, MinusCircle } from '@/components/svg'
import { useAccordionContext } from './Accordion'

const AccordionSummary = ({
  index,
  children,
  className,
}: {
  index: number
  children: React.ReactNode
  className?: string
}) => {
  const { expanded, setExpanded } = useAccordionContext()
  const isExpanded = expanded === index
  const ToggleIcon = isExpanded ? MinusCircle : PlusCircle

  return (
    <ButtonBase
      className={className}
      onClick={() => setExpanded(isExpanded ? -1 : index)}
    >
      <div className="flex items-center">
        <div className="text-left pr-4">{children}</div>
        <ToggleIcon className="w-4 h-4 sm:w-5 sm:h-5 text-oxide-3 shrink-0 ml-auto" />
      </div>
    </ButtonBase>
  )
}

export default AccordionSummary
