import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Popover as UIPopover } from '@headlessui/react'
import { Text } from '@/components/common'
import {
  BellNotification as Notification,
  UpdateNotifiction,
} from '@/components/context/NotificationsContext/NotificationsContext'

export interface Props extends Omit<Notification, 'type'> {
  updateNotification: UpdateNotifiction
  className?: string
}

export const BellNotification: React.FC<Props> = ({
  id,
  text,
  seen,
  onClick,
  updateNotification,
  className,
}) => {
  useEffect(() => {
    return () => {
      if (!seen) {
        updateNotification(id, { seen: true })
      }
    }
  }, [updateNotification, id, seen])

  return (
    <li
      className={classNames(
        {
          'text-black list-disc marker:text-xl marker:leading-none cursor-pointer':
            true,
          'marker:text-transparent': seen,
          'marker:text-oxide-3': !seen,
        },
        className
      )}
      onClick={onClick}
    >
      <UIPopover.Button className="text-left">
        <Text as="span" preset="body.md">
          {text}
        </Text>
      </UIPopover.Button>
    </li>
  )
}

export default BellNotification
