import classnames from 'classnames'
import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { ISocialShareBlockFields } from 'src/types/generated/contentful'
import Text from '@/components/common/Text/Text'
import TextBlock from '@/components/common/TextBlock'
import { contentUtil } from '@/utils'
import SocialLink from './SocialLink'
import Image from '@/components/contentful/Image'
import { useCampaignAssets } from '@/services/hooks/useCampaignAssets'

const textOverrides = {
  [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
    return (
      <Text as="p" preset="body.md">
        {children}
      </Text>
    )
  },
  [BLOCKS.HEADING_4]: (node: any, children: any) => {
    return (
      <Text
        as="h4"
        preset="custom"
        className="text-base leading-normal tracking-none mb-1"
      >
        {children}
      </Text>
    )
  },
}

interface Props {
  className?: string
  content: ISocialShareBlockFields
}

const CampaignShareBox: React.FC<Props> = ({ content, className }) => {
  const { links, text } = content
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { image } = useCampaignAssets()

  return (
    <div
      className={classnames(
        'bg-gray-10 border border-gray-8 items-start rounded-lg pt-4 px-6 pb-6 w-full md:px-4 md:pb-4 md:flex',
        className
      )}
    >
      {image && (
        <div className="mb-4 md:flex-[0_0_224px] md:mb-0 md:mr-4">
          <Image image={image} className="w-full rounded-lg" width={534} />
        </div>
      )}
      <div>
        {text && (
          <TextBlock
            document={text}
            className="text-left md:mb-3"
            optionOverrides={textOverrides}
          />
        )}
        <div className="md:flex md:mx-[-0.25rem]">
          {links.map((rawLink) => {
            const link = contentUtil.mapContent(rawLink)
            if (!link) return null

            return (
              <SocialLink
                key={link.id}
                className="mb-2 last:mb-0 md:mb-0 md:flex-[1_1] md:mx-1"
                emailSubject={link.emailSubject}
                name={link.name}
                icon={link.icon}
                preconfigured={link.preconfigured}
                url={link.url}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CampaignShareBox
