import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import { Popover } from '@/components/common'
import { Bell } from '@/components/svg'
import useNotifications from '@/services/hooks/useNotifications'
import { BellNotificationList } from '@/components/notifications/BellNotifications'
import ABTester, { ABTestProps } from '@/components/ABTester'

interface Props {
  disabled?: boolean
}

export const BellNotificationPopover: React.FC<Props> = ({ disabled }) => {
  const { notifications } = useNotifications()

  const hasNotifications = useMemo(
    () => notifications.filter((n) => n.type === 'bell' && !n.seen).length > 0,
    [notifications]
  )

  if (disabled) return null

  return (
    <Popover
      content={<BellNotificationList className="w-[280px] p-3 pl-0 ml-10" />}
      placement="bottom"
      className="mr-2"
      label={
        hasNotifications ? 'View unseen notifications' : 'View notifications'
      }
      theme="light"
    >
      <div className="relative">
        <Bell className="w-7 h-7" />
        {hasNotifications && (
          <div className="absolute top-[3px] right-[3px] w-2 h-2 bg-oxide-3 rounded-full" />
        )}
      </div>
    </Popover>
  )
}

const ABBellNotificationPopover = () => {
  const router = useRouter()
  const inFunnel = useMemo(() => router.asPath.includes('/invest/'), [router])

  return (
    <ABTester name="user_notifications_guard_funnel">
      {({ decision }: ABTestProps) => {
        return (
          <BellNotificationPopover
            disabled={decision.variationKey === 'on' && inFunnel}
          />
        )
      }}
    </ABTester>
  )
}

export default ABBellNotificationPopover
