import axios from 'axios'
import { ICampaignAssets } from 'src/types/generated/contentful'

interface CampaignAssetsRequest {
  slug: string | null
}

const BASE_ENDPOINT = '/api/campaign-assets'

export const getCampaignAssets = ({ slug }: CampaignAssetsRequest) => ({
  key: `${BASE_ENDPOINT}/${slug}`,
  request: () => axios.get(`${BASE_ENDPOINT}?slug=${slug}`),
  resolve: (res: { data: ICampaignAssets }) => res.data,
})
