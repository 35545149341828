import axios from 'axios'
import { ISiteAssetsFields } from 'src/types/generated/contentful'
import { resolveSiteAssets } from './resolver'
import { Domain } from './types'

const BASE_ENDPOINT = '/api/site-assets'

export const getSiteAssets = (domain: Domain = 'invest.angel.com') =>
  axios
    .get(`${BASE_ENDPOINT}?id=${encodeURIComponent(domain)}`)
    .then((res: { data: { siteAssets: ISiteAssetsFields } }) =>
      resolveSiteAssets(res.data.siteAssets, domain)
    )

export default { BASE_ENDPOINT, getSiteAssets }
