const safelist = require('./src/components/common/Text/safelist.js')
const rem = 16

module.exports = {
  mode: 'jit',
  content: [
    './src/components/**/*.{js,ts,jsx,tsx}',
    './pages/**/*.{js,ts,jsx,tsx}',
  ],
  safelist,
  theme: {
    fontFamily: {
      whitney: ['WhitneySsm', 'sans-serif'],
    },
    fontSize: {
      '4xl': `${60 / rem}rem`,
      '3xl': `${48 / rem}rem`,
      '2xl': `${36 / rem}rem`,
      xl: `${24 / rem}rem`,
      lg: `${18 / rem}rem`,
      base: `1rem`,
      sm: `${14 / rem}rem`,
      xs: `${12 / rem}rem`,
      '2xs': `${10 / rem}rem`,
    },
    lineHeight: {
      none: 1,
      tight: 1.2,
      normal: 1.333,
      relaxed: 1.5,
      loose: 1.714,
    },
    letterSpacing: {
      tightest: '-0.05em',
      tighter: '-0.03em',
      tight: '-0.02em',
      none: 0,
      wide: '0.05em',
    },
    fontWeight: {
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    colors: {
      white: '#FFF',
      black: '#040D14',
      gray: {
        1: '#20282C', // Outer Space
        2: '#3C4246', // Mako
        3: '#555C61', // Abbey
        4: '#63696F', // Nevada
        5: '#778086', // Rolling Stone
        DEFAULT: '#778086', // Rolling Stone
        6: '#949DA4', // Regent Gray
        7: '#C1C6CB', // Loblolly
        8: '#DADFE3', // Geyser Gray
        9: '#F3F6F9', // Catskill White
        10: '#FCFCFD', // Athens
        300: '#DDDAD7',
      },
      oxide: {
        1: '#005E50', // Dark
        2: '#09907A', // Default
        DEFAULT: '#09907A', // Default
        3: '#39C4A6', // Light
        disabled: 'rgba(9, 144, 122, 0.2)',
        20: 'rgba(100, 181, 154, 0.2)', // 20%
        30: 'rgba(100, 181, 154, 0.3)', // 30%
        60: 'rgba(100, 181, 154, 0.6)', // 60%
        800: 'rgb(31, 132, 110)',
        gradient: { start: '#39C4A6', end: '#005E50' },
      },
      copper: {
        1: '#DB701C', // Dark
        2: '#EB7D26', // Default
        DEFAULT: '#EB7D26', // Default
        3: '#F8B37C', // Light
        disabled: 'rgba(235, 125, 38, 0.2)',
        20: 'rgba(245, 134, 53, 0.2)', // 20%
        30: 'rgba(245, 134, 53, 0.3)', // 30%
        60: 'rgba(245, 134, 53, 0.6)', // 60%
        gradient: { start: '#F8B37C', end: '#DB701C' },
      },
      yellow: '#FFCD36',
      green: '#4BB53E',
      red: '#E0360B',
      transparent: 'transparent',

      // LEGACY: DO NOT USE
      primary: '#59c0a5',
      secondary: '#b8846c',
      darkGray: '#707070',
      lightGray: '#DFDFDF',
      veryLightGray: '#F3F3F3',
      googleRed: '#DE5246',
      facebookBlue: '#4267B2',
      orange: '#FD9626',
      ctaAccent: '#31605D',
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
    extend: {
      minWidth: {
        sm: '24rem',
        md: '28rem',
        lg: '32rem',
        xl: '36rem',
        '2xl': '42rem',
        '3xl': '48rem',
      },
      boxShadow: {
        DEFAULT: 'rgb(192 192 192) 1px 3px 8px',
        card: '0px 16px 30px -5px rgba(0, 0, 0, 0.6)',
        section: '0px 8px 20px rgba(0, 0, 0, 0.2)',
      },
      animation: {
        'spin-slow': 'spin 2s linear infinite',
      },
    },
  },
  plugins: [],
}
