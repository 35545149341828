import classnames from 'classnames'
import { ReactNode } from 'react'

interface Props {
  className?: string
  highlights: (string | ReactNode)[]
}

const Highlights = ({ className, highlights }: Props) => {
  return (
    <ol className={classnames('[counter-reset:li] m-3 md:m-0 mb-8', className)}>
      {highlights.map((message, index) => (
        <li
          key={`highlight-${index}`}
          className={classnames(
            'flex flex-row items-center mb-5',
            'before:items-center before:bg-white before:rounded-full before:shadow before:content-[counter(li)] before:[counter-increment:li] before:flex before:font-whitney before:text-lg before:h-5 before:justify-center before:mr-3 before:p-5 before:w-5'
          )}
        >
          {message}
        </li>
      ))}
    </ol>
  )
}

export default Highlights
