import { useState, useRef, useEffect, RefObject } from 'react'
import RO from 'resize-observer-polyfill'

const initialMeasure = {
  width: undefined,
  height: undefined,
  top: undefined,
  bottom: undefined,
  left: undefined,
  right: undefined,
  x: undefined,
  y: undefined,
  toJSON: undefined,
}

function useMeasure<T extends Element>(): [
  RefObject<T> | undefined,
  Partial<DOMRectReadOnly>
] {
  const observer = useRef<ResizeObserver>()
  const el = useRef<T>(null)
  const [measure, setMeasure] =
    useState<Partial<DOMRectReadOnly>>(initialMeasure)

  useEffect(() => {
    const handler = (entries: ResizeObserverEntry[]) => {
      setMeasure(entries[0].contentRect)
    }

    if (el.current && !observer.current) {
      observer.current = window.ResizeObserver
        ? new window.ResizeObserver(handler)
        : new RO(handler)
      observer.current.observe(el.current)
    }

    return () => {
      observer.current?.disconnect()
    }
  }, [])

  return [el, measure]
}

export default useMeasure
