import React from 'react'
import classNames from 'classnames'
import { ToastNotification as Notification } from '@/components/context/NotificationsContext/NotificationsContext'
import useNotifications from '@/services/hooks/useNotifications'
import ToastNotification from '../ToastNotification'

export const ToastNotificationList = () => {
  const { notifications, removeNotification } = useNotifications()
  const toasts = notifications.filter(
    (n) => n.type === 'toast'
  ) as Notification[]

  return (
    <div
      className={classNames({
        'fixed bottom-0 left-0 z-[9999] flex flex-col-reverse px-4 transform transition-all duration-300 ease-out':
          true,
        'translate-y-full pointer-events-none': toasts.length === 0,
      })}
    >
      {toasts.map((toast) => (
        <ToastNotification
          key={toast.id}
          {...toast}
          handleRemove={() => removeNotification(toast.id)}
        />
      ))}
    </div>
  )
}

export default ToastNotificationList
