import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import classNames from 'classnames'
import Text from '../Text/Text'
import ButtonBase from '../ButtonBase'
import Button from '../Button'
import Popover from '../Popover/Popover'
import { Document, CloseAlt, Spinner, Exclamation } from '@/components/svg'
import { resolveErrorMessage, ErrorObject } from '@/utils'

export interface Props {
  id?: string
  name: string
  file?: File
  handleUpload?: () => Promise<void>
  handleRemove?: () => Promise<void>
  className?: string
}

const FormDropzoneFile: React.FC<Props> = ({
  id,
  name,
  file,
  handleUpload,
  handleRemove,
  className,
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<JSX.Element | string | null>(null)
  const ranOnce = useRef<boolean>(false)

  const parts = name.split('.')
  const extension = parts[parts.length - 1]
  const filename = parts.slice(0, parts.length - 1).join('.')

  const handleUploadStatefully = useCallback(async () => {
    if (!handleUpload) {
      return
    }

    setLoading(true)
    setError(null)
    try {
      await handleUpload()
    } catch (e) {
      setError(resolveErrorMessage(e as ErrorObject))
    } finally {
      setLoading(false)
    }
  }, [handleUpload])

  const handleRemoveStatefully = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!handleRemove) {
        return
      }

      e.preventDefault()
      setLoading(true)
      setError(null)
      try {
        await handleRemove()
      } catch (error) {
        setLoading(false)
        setError(resolveErrorMessage(error as ErrorObject))
      }
    },
    [handleRemove]
  )

  // If an upload handler is passed, and file not already uploaded,
  // then upload the file as soon as the component renders
  useEffect(() => {
    if (!id && !ranOnce.current) {
      ranOnce.current = true
      handleUploadStatefully()
    }
  }, [file, loading, id, handleUploadStatefully])

  const action = useMemo(() => {
    if (loading) {
      return (
        <div className="relative z-20 ml-auto p-1">
          <Spinner className="w-4 h-4 text-gray-4 animate-spin-slow" />
        </div>
      )
    }

    if (error) {
      const content = (
        <div className="flex flex-col items-center p-4">
          <Text as="p" preset="body.sm" className="text-white">
            {error}
          </Text>
          <Button
            color="oxide"
            size="sm"
            onClick={id ? handleRemoveStatefully : handleUploadStatefully}
            className="w-full mt-2"
            type="button"
          >
            Retry
          </Button>
          {!id && (
            <Button
              color="gray"
              size="sm"
              onClick={handleRemoveStatefully}
              className="w-full mt-2"
              type="button"
            >
              Remove
            </Button>
          )}
        </div>
      )

      return (
        <Popover
          content={content}
          className="relative z-20 ml-auto p-1 block"
          label="File Error"
        >
          <Exclamation className="w-4 h-4 text-red" />
        </Popover>
      )
    }

    if (handleRemove) {
      return (
        <ButtonBase
          onClick={handleRemoveStatefully}
          className="relative z-20 ml-auto p-2"
          aria-label="Remove File"
          type="button"
        >
          <CloseAlt className="w-2 h-2 text-gray-4" />
        </ButtonBase>
      )
    }

    return null
  }, [
    loading,
    error,
    id,
    handleRemove,
    handleUploadStatefully,
    handleRemoveStatefully,
  ])

  return (
    <div
      key={name}
      className={classNames(
        {
          'relative bg-white border rounded flex items-center p-1 pl-7': true,
          'border-gray-8': !error,
          'border-red': error,
          'opacity-50 pointer-events-none cursor-default': loading,
        },
        className
      )}
    >
      <Document
        className={classNames({
          'block w-6 h-6 absolute left-[3px] top-1': true,
          'text-oxide': !error,
          'text-red': error,
        })}
      />

      <Text as="span" preset="body.sm" className="truncate">
        {`${filename}.`}
      </Text>

      <Text as="span" preset="body.sm">
        {extension}
      </Text>

      {action}
    </div>
  )
}

export default FormDropzoneFile
