import React, { createContext, useContext } from 'react'
import AccordionSummary from './AccordionSummary'
import AccordionDetails from './AccordionDetails'

interface AccordionContext {
  expanded: number
  setExpanded: (index: number) => void
  speed: 'slow' | 'normal' | 'fast'
}

const AccordionContext = createContext<AccordionContext>({
  expanded: -1,
  setExpanded: () => null,
  speed: 'normal',
})

export const useAccordionContext = () => useContext(AccordionContext)

interface AccordionType extends AccordionContext {
  children: React.ReactNode
}

const Accordion = ({
  expanded,
  setExpanded,
  speed = 'normal',
  children,
}: AccordionType) => (
  <AccordionContext.Provider
    value={{
      expanded,
      setExpanded,
      speed,
    }}
  >
    {children}
  </AccordionContext.Provider>
)

Accordion.Summary = AccordionSummary

Accordion.Details = AccordionDetails

export default Accordion
