import { ChevronDown } from '@/components/svg'
import { Campaign } from '@/services/api/campaign'
import { ChatbotMessage } from '@/services/api/chatbot'
import useInvestmentChatbot from '@/services/hooks/useInvestmentChatbot'
import { useSubscribeInvestmentChatbot } from '@/services/hooks/useSubscribeInvestmentChatbot'
import { report, resolveErrorMessage } from '@/utils'
import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ButtonBase from '../ButtonBase'
import Text from '../Text/Text'
import Message from './Message'
import { scrollToBottomOfElement } from './utils'

interface Props {
  campaign: Campaign
  className?: string
}

const ChatBot: React.FC<Props> = ({ campaign, className }) => {
  const [isFollowing, setFollowing] = useState(true)
  const [messages, setMessages] = useState<ChatbotMessage[]>([])
  const scrollPositionRef = useRef<number>(0)
  const scrollableContainer = useRef<HTMLDivElement>(null)
  const { isError, data } = useInvestmentChatbot(campaign.slug)

  const { error: subscribeError } = useSubscribeInvestmentChatbot(
    campaign.channelId,
    setMessages
  )

  useEffect(() => {
    if (!data) return

    setMessages(data)
  }, [data])

  useEffect(() => {
    const element = scrollableContainer.current
    if (element && isFollowing) {
      scrollPositionRef.current = element.scrollHeight
      scrollToBottomOfElement(element)
    }
  }, [messages, isFollowing])

  const onScroll = () => {
    const chatContainer = scrollableContainer.current

    if (!chatContainer) {
      return
    }

    if (
      chatContainer.scrollTop + chatContainer.clientHeight >=
      scrollPositionRef.current
    ) {
      setFollowing(true)
    } else {
      setFollowing(false)
    }
  }

  const stopFollowing = useCallback(() => {
    setFollowing(false)
  }, [setFollowing])

  const startFollowing = useCallback(() => {
    setFollowing(true)
  }, [setFollowing])

  if (isError) {
    report.error(resolveErrorMessage(isError as Error))
    return null
  }
  // report it but still show recent investments, i.e. continue rendering
  if (subscribeError) {
    report.error(resolveErrorMessage(subscribeError))
  }

  return (
    <div className={classNames('relative overflow-hidden', className)}>
      <Text
        as="h3"
        preset="heading.sm"
        className="absolute top-0 w-full border border-[#E2E1E2] bg-[#F6F5F6] p-4 rounded-t text-gray-2 shadow-md z-10"
      >
        Investment Updates
      </Text>
      <div
        className="h-72  overflow-y-scroll border border-[#E2E1E2] bg-white px-4 pb-2 pt-16 rounded-b scrollbar"
        ref={scrollableContainer}
        onScroll={onScroll}
        onClick={stopFollowing}
        onTouchMove={stopFollowing}
        onMouseDown={stopFollowing}
      >
        {messages.map((message) => (
          <Message message={message} key={message.id} />
        ))}
      </div>

      <ButtonBase
        className={classNames(
          'bg-gray-1 mx-auto drop-shadow-xl h-[38px] w-[38px] p-3 -mt-4 rounded-full flex items-center justify-center z-10 transition-opacity delay-100',
          {
            'opacity-0': isFollowing,
          }
        )}
        onClick={startFollowing}
      >
        <ChevronDown className={'stroke-white text-white'} />
      </ButtonBase>
    </div>
  )
}

export default ChatBot
