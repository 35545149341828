import React from 'react'
import { Button, Text } from '@/components/common'
import { BackArrow } from '@/components/svg'

export interface Props {
  page: number
  totalPages: number
  onNext?: () => void
  onPrevious?: () => void
  className?: string
}

const PaginationControls: React.FC<Props> = ({
  page,
  totalPages,
  onNext,
  onPrevious,
  className,
}) => {
  if (totalPages < 2) {
    return null
  }

  return (
    <div className={className}>
      <Button
        size="sm"
        onClick={onPrevious}
        disabled={!onPrevious}
        aria-label="Go to Previous Page"
      >
        <div className="w-6 -mx-2">
          <BackArrow />
        </div>
      </Button>

      <Text as="span" preset="body.md" className="mx-3">
        Page {page} of {totalPages}
      </Text>

      <Button
        size="sm"
        onClick={onNext}
        disabled={!onNext}
        aria-label="Go to Next Page"
      >
        <div className="w-6 -mx-2">
          <BackArrow className="rotate-180" />
        </div>
      </Button>
    </div>
  )
}

export default PaginationControls
