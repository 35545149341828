import { casingUtil } from '@/utils'

export const resolveBirthday = (birthday) => {
  if (birthday) {
    const [year, month, day] = birthday.split('-')
    return `${month}-${day}-${year}`
  }

  return birthday
}

export const resolveBirthdayPayload = (birthday) => {
  if (birthday) {
    const [month, day, year] = birthday.split('-')
    return `${year}-${month}-${day}`
  }

  return birthday
}

export const resolvePhoneNumber = (phoneNumber) => {
  // if no value return empty string
  if (!phoneNumber) return ''

  // if there's no country code, cast to US
  if (!phoneNumber.includes('+')) {
    return `+1${phoneNumber}`
  }

  return phoneNumber
}

export const resolveFilePayload = (file) => {
  const fileObj = Array.isArray(file) ? file[0] : file
  const form = new FormData()
  form.append('file', fileObj, fileObj.name)
  return form
}

export const resolvePledgePayload = ({
  firstName: first_name,
  lastName: last_name,
  displayAnonymous: display_anonymous,
  ...rest
}) => ({
  first_name,
  last_name,
  display_anonymous,
  ...rest,
})

export const resolveComment = casingUtil.snakeToCamel

export const resolveIVA = (action) => {
  const {
    id,
    party,
    entity,
    investment,
    requireAddress,
    requireDob,
    requireDriversLicense,
    requireName,
    requireSsn,
    requireOwnerInfo,
    requireProofOfAddress,
    requireProofOfEin,
    requireProofOfSsn,
    status,
    ...rest
  } = casingUtil.snakeToCamel(action)

  return {
    ...rest,
    id,
    partyId: party,
    status,
    entityId: entity,
    investmentId: investment,
    errors: {
      requireAddress,
      requireDob,
      requireDriversLicense,
      requireName,
      requireSsn,
      requireOwnerInfo,
      requireProofOfAddress,
      requireProofOfEin,
      requireProofOfSsn,
    },
  }
}

export const resolveIVAPayload = (form) => {
  const formData = new FormData()
  const resolvedForm = { ...form }

  if (resolvedForm.dateOfBirth) {
    const [month, day, year] = resolvedForm.dateOfBirth.split('-')
    const resolvedDateOfBirth = `${year}-${month}-${day}`
    resolvedForm.dateOfBirth = resolvedDateOfBirth
  }

  Object.entries(resolvedForm).forEach(([name, value]) => {
    if (
      name === 'proofOfSsn' ||
      name === 'proofOfAddress' ||
      name === 'driversLicense' ||
      name === 'ownerInfo' ||
      name === 'proofOfEin'
    ) {
      const fileObj = Array.isArray(value) ? value[0] : value
      formData.append(casingUtil.camelToSnakeStr(name), fileObj, fileObj.name)
    } else {
      formData.append(casingUtil.camelToSnakeStr(name), value)
    }
  })

  return formData
}

export const resolveIssuer = ({ metabase_dashboards, ...rest }) => ({
  ...casingUtil.snakeToCamel(rest),
  metabaseDashboards: metabase_dashboards.map((item) => {
    const [id, name] = Object.entries(item)[0]
    return { id, name }
  }),
})

export const resolveMetabase = casingUtil.snakeToCamel

export const resolveUser = ({ metabase_dashboards = [], ...rest }) => ({
  ...rest,
  metabase_dashboards: metabase_dashboards.map((item) => {
    const [id, name] = Object.entries(item)[0]
    return { id, name }
  }),
})
