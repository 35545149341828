import axios from 'axios'
import { getPersistedAuth } from '@/components/context/AuthContext/utils'

export const baseURL =
  process.env.NEXT_PUBLIC_THIN_MINT_API_URL ||
  'https://thin-mint.angelstudios.com/api'

const apiClient = axios.create({ baseURL })

// Add auth header to requests if token exists
apiClient.interceptors.request.use((config) => {
  const resolvedConfig = { ...config }

  const { token } = getPersistedAuth()
  if (token) {
    resolvedConfig.headers.Authorization = token
  }

  return resolvedConfig
})

export default apiClient
