import React, { useState, useMemo } from 'react'
import classNames from 'classnames'
import { useField } from 'formik'
import FormError from '../FormError'
import Text from '../../Text/Text'
import FormFieldSecretToggle from './FormFieldSecretToggle'
import { renderInput, renderFileInput } from './utils'
import { Props } from './types'

const FormField: React.FC<Props> = ({
  name,
  displayName,
  type,
  helpText,
  isSecret = false,
  prefix,
  className,
  ...rest
}) => {
  const [field, meta] = useField(name)
  const [isSecretMasked, setSecretMasked] = useState(isSecret)
  const hasError = useMemo(
    () => meta.touched && !!meta.error,
    [meta.touched, meta.error]
  )

  return (
    <div className={className}>
      <Text
        as="label"
        preset="body.sm"
        id={`${name}-label`}
        htmlFor={name}
        className={classNames({
          'block mb-[2px]': true,
          'text-gray-5': !hasError,
          'text-red': hasError,
        })}
      >
        {displayName}
      </Text>

      <div className="relative">
        {/* Number inputs have their own prefix handling */}
        {prefix && type !== 'number' && (
          <Text
            as="div"
            preset="body.md"
            className="absolute left-2 top-[50%] -translate-y-[50%] text-right w-4"
          >
            {prefix}
          </Text>
        )}

        {type === 'file' &&
          renderFileInput({
            field,
            name,
            hasError,
            ...rest,
          })}

        {type !== 'file' &&
          renderInput({
            field,
            name,
            type,
            prefix,
            isSecretMasked,
            hasError,
            ...rest,
          })}

        {isSecret && field.value && (
          <FormFieldSecretToggle
            name={name}
            isSecretMasked={isSecretMasked}
            setSecretMasked={setSecretMasked}
          />
        )}
      </div>

      {helpText && (
        <Text as="div" preset="body.xs sm:body.sm" className="text-gray-2 mt-1">
          {helpText}
        </Text>
      )}

      <FormError error={hasError ? (meta.error as string) : null} />
    </div>
  )
}

export default FormField
