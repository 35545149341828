import React from 'react'
import { BellNotification as Notification } from '@/components/context/NotificationsContext/NotificationsContext'
import useNotifications from '@/services/hooks/useNotifications'
import BellNotification from './BellNotification'

interface Props {
  className?: string
}

export const BellNotificationList: React.FC<Props> = ({ className }) => {
  const { notifications, updateNotification } = useNotifications()
  const bells = notifications.filter((n) => n.type === 'bell') as Notification[]

  return (
    <ul className={className}>
      {bells.map((bell) => (
        <BellNotification
          key={bell.id}
          {...bell}
          updateNotification={updateNotification}
          className="mb-2 last:mb-0"
        />
      ))}

      {bells.length === 0 && (
        <li className="text-gray text-sm text-center -ml-6">
          No notifications right now
        </li>
      )}
    </ul>
  )
}

export default BellNotificationList
