import { SWRConfiguration } from 'swr'
import useAuth from '@/services/hooks/useAuth'
import { Investment, getInvestments } from '@/services/api/investment'
import useApi from '../useApiV2'

const useInvestments = (swrOptions?: SWRConfiguration) => {
  const { user } = useAuth()

  const { data, ...rest } = useApi<Investment[]>({
    key: user ? 'investments' : false,
    request: getInvestments,
    swrOptions,
  })

  return { investments: data, ...rest }
}

export default useInvestments
