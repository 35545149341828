import classNames from 'classnames'
import { Field } from '../types'
import FormField from '../FormField'

export interface Props {
  fields: Field[][]
  overrideMobileSingleColumn?: boolean
  className?: string
}

const FormBuilder: React.FC<Props> = ({
  fields,
  overrideMobileSingleColumn = false,
  className,
}) => {
  return (
    <div className={className}>
      <div className="flex flex-col w-full">
        {fields.map((rowItems, idx) => (
          <div
            key={idx}
            className={classNames({
              'w-full': true,
              'sm:grid gap-8': rowItems.length > 1,
              'sm:grid-cols-2': rowItems.length === 2 || rowItems.length > 3,
              'sm:grid-cols-3': rowItems.length === 3,
              'grid gap-8 grid-cols-2':
                overrideMobileSingleColumn && rowItems.length > 1,
            })}
          >
            {rowItems.map((field) => (
              <FormField
                key={field.name}
                {...field}
                className={field.className || 'min-w-0 py-2'}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default FormBuilder
