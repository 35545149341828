import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Text } from './common'

interface Component {
  children: any
}

interface Anchor extends Component {
  href: string
}

interface ListItem extends Component {
  ordered: boolean
}

const components = {
  a: ({ children, href }: Anchor) => {
    return (
      <a className="text-oxide" href={href}>
        {children}
      </a>
    )
  },
  h1: ({ children }: Component) => {
    return (
      <Text as="h1" preset="heading.lg" className="mb-4">
        {children}
      </Text>
    )
  },
  h2: ({ children }: Component) => {
    return (
      <Text as="h2" preset="heading.md" className="mb-4">
        {children}
      </Text>
    )
  },
  h3: ({ children }: Component) => {
    return (
      <Text as="h3" preset="heading.sm" className="mb-4">
        {children}
      </Text>
    )
  },
  h4: ({ children }: Component) => {
    return (
      <Text as="h4" preset="heading.xs" className="mb-4">
        {children}
      </Text>
    )
  },
  h5: ({ children }: Component) => {
    return (
      <Text as="h5" preset="heading.xs" className="mb-4">
        {children}
      </Text>
    )
  },
  h6: ({ children }: Component) => {
    return (
      <Text as="h6" preset="heading.xs" className="mb-4">
        {children}
      </Text>
    )
  },
  p: ({ children }: Component) => {
    return (
      <Text as="p" preset="body.md" className="mb-4 last:mb-0">
        {children}
      </Text>
    )
  },
  li: ({ children, ordered }: ListItem) => {
    return (
      <Text
        as="li"
        preset="body.md"
        className={classNames('mb-4 ml-4', {
          'list-decimal': ordered,
          'list-disc': !ordered,
        })}
      >
        {children}
      </Text>
    )
  },
}

export default function Markdown({ source }: { source: string }) {
  return (
    // https://github.com/rehypejs/rehype/discussions/63 for explanation
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <ReactMarkdown rehypePlugins={[rehypeRaw]} components={components}>
      {source}
    </ReactMarkdown>
  )
}
