import classnames from 'classnames'
import { Asset } from 'contentful'
import React, { useEffect, useMemo, useState } from 'react'
import { FacebookCircle, TwitterCircle, EmailCircle } from '@/components/svg'
import Image from '@/components/contentful/Image'
import { useCampaignAssets } from '@/services/hooks/useCampaignAssets'

interface Props {
  className?: string
  emailSubject?: string
  icon?: Asset
  name: string
  preconfigured: 'Custom' | 'Facebook' | 'Twitter' | 'Email'
  url?: string
}

const SocialLink: React.FC<Props> = ({
  className,
  emailSubject: rawEmailSubject,
  icon,
  name,
  preconfigured,
  url,
}) => {
  const [shareUrl, setShareUrl] = useState('')
  const { name: campaignName, slug } = useCampaignAssets()

  const body = encodeURIComponent(shareUrl)
  const emailSubject =
    rawEmailSubject &&
    rawEmailSubject.replace(/{{campaign.name}}/g, String(campaignName))
  const subject = encodeURIComponent(emailSubject || '')

  useEffect(() => {
    if (!slug) return
    setShareUrl(`${window.location.origin}/${slug}?utm_medium=share_button`)
  }, [slug])

  const items = useMemo(
    () => ({
      Custom: {
        Icon: null,
        href: url,
      },
      Email: {
        Icon: EmailCircle,
        href: `mailto:?subject=${subject}&body=${body}`,
      },
      Facebook: {
        Icon: FacebookCircle,
        href: `https://www.facebook.com/sharer/sharer.php?u=${body}`,
      },
      Twitter: {
        Icon: TwitterCircle,
        href: `https://twitter.com/intent/tweet?url=${body}`,
      },
    }),
    [body, subject, url]
  )
  const { href, Icon } = items[preconfigured]

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={classnames(
        'border border-gray-7 cursor-pointer items-center flex p-2 rounded text-sm hover:border-gray-5',
        className
      )}
    >
      <span className="inline-block mr-2 mt-[-3px] w-6">
        {icon ? <Image image={icon} width={24} /> : <Icon />}
      </span>
      {name}
    </a>
  )
}

export default SocialLink
