import React from 'react'
import classNames from 'classnames'
import { ButtonBase } from '@/components/common'

export interface Props {
  name?: string
  isSelected: boolean
  hasError?: boolean
  onClick: () => void
  className?: string
  children: React.ReactNode
}

export const RadioOptionBox: React.FC<Props> = ({
  name,
  onClick,
  isSelected,
  hasError = false,
  className,
  children,
}) => {
  return (
    <div className={className}>
      <div
        className={classNames({
          'rounded-lg border transition-all duration-200 ease-in-out': true,
          border: !isSelected,
          'ring-1': isSelected,
          'border-red ring-red': hasError,
          'border-gray-7 ring-gray-7': !isSelected && !hasError,
          'border-oxide ring-oxide': isSelected && !hasError,
        })}
      >
        <ButtonBase
          onClick={onClick}
          type="button"
          className="w-full flex items-center text-left rounded-lg focus:outline-none"
          name={name}
        >
          <div className="flex-1">{children}</div>

          <div
            className={classNames('w-4 h-4 p-[2px] rounded-full border mr-2', {
              'border-gray-7': !isSelected && !hasError,
              'border-oxide': isSelected && !hasError,
              'border-red': hasError,
            })}
          >
            {isSelected && (
              <div
                className={classNames('w-[10px] h-[10px] rounded-full', {
                  'bg-red': hasError,
                  'bg-oxide': isSelected && !hasError,
                })}
              />
            )}
          </div>
        </ButtonBase>
      </div>
    </div>
  )
}

export default RadioOptionBox
