import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Warning } from '../svg'

export const colorHash = {
  red: 'border-[#CB382F] bg-[#CB382F]',
  yellow: 'bg-[#fbc22d] border-[#FBC22D]',
}

const ErrorBox = ({ errorText, color = 'red', size = 'lg', className }) => {
  return errorText ? (
    <div
      className={classNames(
        {
          'font-whitney text-lg rounded text-black bg-opacity-10 flex flex-row items-center': true,
          'p-2.5 border-2': size === 'lg',
          'p-1.5 border': size === 'sm',
          [colorHash[color]]: true,
        },
        className
      )}
    >
      <div className="h-full flex flex-col items-center justify-center">
        <Warning
          style={{
            color: color === 'yellow' ? '#fbc22d' : '#CB382F',
            fontSize: size === 'lg' ? 35 : 25,
          }}
        />
      </div>
      <span
        className={classNames({
          'ml-2': true,
          'text-sm': size === 'lg',
          'text-xs': size === 'sm',
        })}
      >
        {errorText}
      </span>
    </div>
  ) : null
}

ErrorBox.propTypes = {
  color: PropTypes.oneOf(Object.keys(colorHash)),
  size: PropTypes.oneOf(['lg', 'sm']),
  className: PropTypes.string,
}

export default ErrorBox
