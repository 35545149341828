import { casingUtil } from '@/utils'
import client from '../thinMintClient'
import { SnakeToCamel } from '../types'

export interface ChatbotMessagePayload {
  id: string
  amount: number
  completed: number
  first_name: string
  state: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ChatbotMessage extends SnakeToCamel<ChatbotMessagePayload> {}

export const resolveChatbotPayload = (messages: ChatbotMessagePayload[]) => {
  return messages.map(casingUtil.snakeToCamel) as unknown as ChatbotMessage
}

export const getChatbotMessages = (slug: string) =>
  client
    .get(`investment-chatbot?campaign=${slug}`)
    .then((res: { data: ChatbotMessagePayload[] }) => {
      return resolveChatbotPayload(res.data)
    })
