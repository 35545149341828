import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useMeasure from '@/services/hooks/useMeasure'

function HeightTransitioner({
  className,
  initialHeight,
  speed = 'normal',
  children,
}) {
  const [ref, { height = initialHeight }] = useMeasure()

  return (
    <div className={className}>
      <div
        className={classNames({
          'overflow-hidden transition-[height] ease-in-out': true,
          'duration-500': speed === 'normal',
          'duration-300': speed === 'fast',
          'duration-700': speed === 'slow',
        })}
        style={{ height }}
      >
        <div ref={ref}>{children}</div>
      </div>
    </div>
  )
}

HeightTransitioner.propTypes = {
  className: PropTypes.string,
  initialHeight: PropTypes.number,
  speed: PropTypes.oneOf(['slow', 'normal', 'fast']),
  children: PropTypes.node,
}

export default HeightTransitioner
