import React, { HTMLInputTypeAttribute } from 'react'
import { FormInput } from '../types'

interface Props extends FormInput {
  type?: HTMLInputTypeAttribute
}

const FormInputDefault: React.FC<Props> = ({
  type = 'text',
  value,
  ...rest
}) => <input {...rest} type={type} value={value || ''} />

export default FormInputDefault
