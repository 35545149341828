import client from '../thinMintClient'
import { resolveIssuer } from '../resolvers'

const baseEndpoint = '/issuers'

export interface Issuer {
  id: number
  created: string
  modified: string
  name: string
  slug: string
  team: string[]
  metabaseDashboards: {
    id: string
    name: 'Investment' | 'Expressed Interest'
  }[]
  portalContacts: {
    name: string
    role: string
    email: string
  }[]
}

export interface IssuerPayload extends Omit<Issuer, 'metabaseDashboards'> {
  metabase_dashboards: { [key: number]: string }[]
}

export const getIssuers = () =>
  client
    .get(`${baseEndpoint}/`)
    .then(
      (res: { data: { results: IssuerPayload[] } }) =>
        res.data.results.map(resolveIssuer) as Issuer[]
    )

export const getIssuer = (issuerId: number) =>
  client
    .get(`${baseEndpoint}/${issuerId}/`)
    .then((res: { data: IssuerPayload }) => resolveIssuer(res.data) as Issuer)

export default {
  baseEndpoint,
  getIssuers,
  getIssuer,
}
