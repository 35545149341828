export const loadGoogleScript = () =>
  new Promise((resolve, reject) => {
    if (window.google?.accounts) return resolve(true)
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.id = 'google-accounts'
    script.onload = resolve
    script.onerror = reject
    document.body.appendChild(script)
  })

export const initCodeClient = (
  reqState: string,
  callback: (res: google.accounts.oauth2.CodeResponse) => void
) =>
  window.google.accounts.oauth2.initCodeClient({
    client_id:
      '286513033991-5muijues887k74a0jboah5au840sthvf.apps.googleusercontent.com',
    scope: 'openid email profile',
    state: reqState,
    response_type: 'code',
    redirect_uri: `${location.protocol}://${location.host}`,
    callback,
  } as google.accounts.oauth2.CodeClientConfig)

export const getReqState = () =>
  Math.ceil(Math.random() * 1000000000000).toString()
