import React from 'react'
import GoogleAuthButtonNew, { Props } from './GoogleAuthButton'
import GoogleAuthButtonOld from '../GoogleAuthButn'
import ABTester, { ABTestProps } from '@/components/ABTester'

const ABGoogleAuthButton: React.FC<Props> = (props) => {
  return (
    <ABTester name="new_google_login">
      {({ decision }: ABTestProps) => {
        if (decision.variationKey === 'on') {
          return <GoogleAuthButtonNew {...props} />
        }
        return <GoogleAuthButtonOld {...props} />
      }}
    </ABTester>
  )
}

export default ABGoogleAuthButton
