/* 
  TODO: Temporary component to get David Reg A campaign card on our homepage.
  We will need to refactor this into a more generic card to suppport more white-labeled sites.
*/

import classnames from 'classnames'
import NextImage from 'next/image'
import React, { useEffect, useState } from 'react'

import Button from '../Button'
import ImageLoader from '../ImageLoader'
import Text from '../Text/Text'
import TextBlock from '../TextBlock'
import InterstitialLink from '../../InterstitialLink'
import Image from '@/components/contentful/Image'
import { ArrowSquareOut } from '@/components/svg'
import { Campaign as ICampaign } from '@/services/api/campaign'
import { getCampaignAssets } from '@/services/api/campaignAssets'
import useApi from '@/services/hooks/useApiV2'
import {
  ICampaignAssets,
  ICampaignAssetsFields,
} from 'src/types/generated/contentful'
import { Domain } from '@/services/api/siteAssets/types'

interface Props {
  campaign: ICampaign
  domain: Domain
  className?: string
  layout?: 'vertical' | 'horizontal'
}

export const InterstitialCampaignCard: React.FC<Props> = ({
  campaign,
  domain,
  className,
  layout = 'vertical',
}) => {
  const [assets, setAssets] = useState<ICampaignAssetsFields | undefined>()
  // 16:9 aspect ratio
  const imgHeight = 341
  const imgWidth = 607 // max width it will need

  const { data, isLoading } = useApi<{ campaignAssets: ICampaignAssets }>(
    getCampaignAssets({ slug: campaign.slug })
  )

  useEffect(() => {
    if (!data?.campaignAssets?.fields) return

    setAssets(data.campaignAssets.fields)
  }, [data])

  return (
    <div
      className={classnames(
        'bg-white flex flex-col h-full shadow shadow-gray-8 rounded-2xl w-full overflow-hidden',
        {
          'sm:flex-row': layout === 'horizontal',
        },
        className
      )}
    >
      <div
        className={classnames({
          'sm:p-6 sm:pr-3 sm:flex-[0_0_50%]': layout === 'horizontal',
        })}
      >
        {isLoading && <ImageLoader />}
        {!isLoading && (
          <InterstitialLink domain={domain}>
            {/* We have an image from Contentful */}
            {assets?.image && (
              <Image
                className={classnames('w-full', {
                  'sm:rounded-lg': layout === 'horizontal',
                })}
                image={assets.image}
                fit="fill"
                fill="face"
                lazy
                // 16/9 aspect ratio
                height={341}
                width={607} // max width it will need
              />
            )}
            {/* Fallback to previewImg from campaign */}
            {!assets?.image && campaign.previewImg && (
              <NextImage
                className={classnames('w-full', {
                  'sm:rounded-lg': layout === 'horizontal',
                })}
                src={campaign.previewImg}
                alt={`${campaign.name} hero`}
                loading="lazy"
                height={imgHeight}
                width={imgWidth}
              />
            )}
          </InterstitialLink>
        )}
      </div>

      <div
        className={classnames('flex flex-col flex-1 pt-4 pb-5 px-6 text-left', {
          'sm:pt-2 sm:pb-4 sm:px-4': layout === 'vertical',
          'sm:p-6 sm:pl-3': layout === 'horizontal',
        })}
      >
        <InterstitialLink domain={domain} className="text-left">
          <Text as="h4" preset="heading.lg">
            {campaign.name}
          </Text>
          <Text as="p" preset="body.lg" className="font-light mb-4">
            {domain}
          </Text>
        </InterstitialLink>

        {assets?.shortDescription && (
          <TextBlock
            campaignSlug={campaign.slug}
            document={assets.shortDescription}
          />
        )}

        <div className="mt-auto">
          <Text as="p" preset="body.lg" className="mb-4">
            You will be redirected to <b>{domain}</b>
          </Text>

          <Button
            variant="primary"
            className="w-full bg-oxide-800 mt-auto rounded-lg"
            tabIndex={-1}
          >
            <InterstitialLink domain={domain} as="div">
              Learn More
              <ArrowSquareOut className="inline-block ml-2 -mt-1" />
            </InterstitialLink>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default InterstitialCampaignCard
