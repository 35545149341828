import { ISiteAssetsFields } from 'src/types/generated/contentful'
import { SiteAssets, Domain } from './types'
import { whiteLabelUtil } from '@/utils'

export const extraConfig = {
  'invest.angel.com': {
    zendeskKey: '361a1ab1-1121-48d7-a18c-c0ce46561cd0',
    cognitoFormsKey: '',
    cognitoFormsId: '',
    timeRemainingDescriptor: 'Left',
    fundingCampaignDisclaimer: '',
    footerDisclaimer: `This website is owned and operated by VAS Portal, LLC doing business as Angel Funding. All funding portal activities are conducted by VAS Portal, LLC dba Angel Funding. Any Reg A or Reg D offering listed on this website are managed by the broker dealer listed on the offering details.`,
    title: 'Angel Funding', // TODO: title is temporarily hard-coded as we determine how best to fetch assets for multiple sites
    usePriorRaisesLayout: false,
    priorRaisesDisclaimer: '',
    campaignSummary: '',
    priorRaisesGoalAmount: 0,
    priorRaisesTitle: '',
    captureChatbotConsent: false,
    isChatbotAllowed: false,
    showSecondaryCta: false,
    hideTimeRemaining: false,
  },
  'thedavidmovie.com': {
    zendeskKey: '',
    cognitoFormsKey: 'E6D1XHWl6EK3GTVlg-eitw',
    cognitoFormsId: '4',
    timeRemainingDescriptor: 'Left*',
    fundingCampaignDisclaimer: `*$60,982,236 represents the current overall funding target for this project. DAVID is currently raising funding toward that goal in a Regulation A investment offering. DAVID is offering investments up to a maximum of $37.5 million in the current offering. More information about the current offering is available in the offering tab below.`,
    footerDisclaimer: `DISCLAIMER:

    THESE OFFERING MATERIALS MAY CONTAIN FORWARD-LOOKING STATEMENTS AND INFORMATION RELATING TO, AMONG OTHER THINGS, THE COMPANY, ITS BUSINESS PLAN AND STRATEGY, AND ITS INDUSTRY. THESE FORWARDLOOKING STATEMENTS ARE BASED ON THE BELIEFS OF, ASSUMPTIONS MADE BY, AND INFORMATION CURRENTLY AVAILABLE TO THE COMPANY’S MANAGEMENT. WHEN USED IN THE OFFERING MATERIALS, THE WORDS “ESTIMATE,” “PROJECT,” “BELIEVE,” “ANTICIPATE,” “INTEND,” “EXPECT” AND SIMILAR EXPRESSIONS ARE INTENDED TO IDENTIFY FORWARD-LOOKING STATEMENTS. THESE STATEMENTS REFLECT MANAGEMENT’S CURRENT VIEWS WITH RESPECT TO FUTURE EVENTS AND ARE SUBJECT TO RISKS AND UNCERTAINTIES THAT COULD CAUSE THE COMPANY’S ACTUAL RESULTS TO DIFFER MATERIALLY FROM THOSE CONTAINED IN THE FORWARD-LOOKING STATEMENTS. INVESTORS ARE CAUTIONED NOT TO PLACE UNDUE RELIANCE ON THESE FORWARD-LOOKING STATEMENTS, WHICH SPEAK ONLY AS OF THE DATE ON WHICH THEY ARE MADE. THE COMPANY DOES NOT UNDERTAKE ANY OBLIGATION TO REVISE OR UPDATE THESE FORWARD-LOOKING STATEMENTS TO REFLECT EVENTS OR CIRCUMSTANCES AFTER SUCH DATE OR TO REFLECT THE OCCURRENCE OF UNANTICIPATED EVENTS.

    PLEASE NOTE INVESTORS IN THIS OFFERING WILL BE CLIENTS OF THE ISSUER AND NOT DALMORE GROUP, LLC (“DALMORE”), A REGISTERED BROKER-DEALER AND MEMBER FINRA/SIPC. DALMORE’S ROLE IN THE TRANSACTION IS TO FACILITATE BACK OFFICE AND REGULATORY FUNCTIONS RELATED TO THE REGULATION A TRANSACTION, AND ACTS ONLY AS THE BROKER/DEALER OF RECORD FOR THE OFFERING LISTED. DALMORE IS NOT PROVIDING INVESTMENT ADVICE OR RECOMMENDATIONS, OR LEGAL OR TAX ADVICE.

    NO MONEY OR OTHER CONSIDERATION IS BEING SOLICITED, AND IF SENT IN RESPONSE, WILL NOT BE ACCEPTED. NO OFFER TO BUY THE SECURITIES CAN BE ACCEPTED AND NO PART OF THE PURCHASE PRICE CAN BE RECEIVED UNTIL THE OFFERING STATEMENT FILED BY THE COMPANY WITH THE SEC HAS BEEN QUALIFIED BY THE SEC. ANY SUCH OFFER MAY BE WITHDRAWN OR REVOKED, WITHOUT OBLIGATION OR COMMITMENT OF ANY KIND, AT ANY TIME BEFORE NOTICE OF ACCEPTANCE GIVEN AFTER THE DATE OF QUALIFICATION. AN INDICATION OF INTEREST INVOLVES NO OBLIGATION OR COMMITMENT OF ANY KIND.

    AN OFFERING STATEMENT REGARDING THIS OFFERING HAS BEEN FILED WITH THE SEC. YOU MAY OBTAIN A COPY OF THE PRELIMINARY OFFERING CIRCULAR THAT IS PART OF THAT OFFERING STATEMENT. AN OFFERING STATEMENT REGARDING THIS OFFERING HAS BEEN FILED WITH THE SEC. THE SEC HAS QUALIFIED THAT OFFERING STATEMENT, WHICH ONLY MEANS THAT THE COMPANY MAY MAKE SALES OF THE SECURITIES DESCRIBED BY THE OFFERING STATEMENT. IT DOES NOT MEAN THAT THE SEC HAS APPROVED, PASSED UPON THE MERITS OR PASSED UPON THE ACCURACY OR COMPLETENESS OF THE INFORMATION IN THE OFFERING STATEMENT. THE OFFERING CIRCULAR THAT IS PART OF THAT OFFERING STATEMENT IS AT: https://www.sec.gov/Archives/edgar/data/1893768/000121465922013223/partiiandiii.htm.

    Slingshot USA, LLC is the issuer (the "Issuer") of the offering listed on the website with Dalmore Group, LLC as the Broker Dealer of Record. This technology platform is provided by Angel Funding for the Issuer.`,
    title: 'The David Movie',
    usePriorRaisesLayout: true,
    priorRaisesDisclaimer: `DAVID is the offering investment in this Regulation A offering. By investing in this Regulation A offering you purchase a membership interest in the Slingshot USA LLC (the entity that owns DAVID) and become a member of the LLC and subject to its operating agreement. The minimum investment amount for this Reg. A round of funding is $100. For more detailed information about the Regulation A Offer please visit the Offering tab which provides the official investment documentation including the Offering Circular for this offering. Please note that investing in this Regulation A offering involves a high degree of risk including the possibility of the total loss of your investment, you should consult with your advisors prior to making an investment decision.`,
    campaignSummary: `If you want to see biblical stories told with exceptional quality on par with Disney or Pixar productions, then DAVID is for you! The biblical story of David is one of the most loved in history. DAVID’s creators are bringing this story to the screen with the moving authenticity of Prince of Egypt paired with the modern animation and musical fun of Moana or Tangled.

Our vision is a movie that delights and entertains a wide global audience, while delivering powerful spiritual impact and providing a glimpse into God’s heart.    

"I have found David son of Jesse, a man after my own heart." (Acts 13:22)`,
    priorRaisesGoalAmount: 6098223600,
    priorRaisesTitle: 'Overall project funding goal',
    captureChatbotConsent: true,
    isChatbotAllowed: true,
    showSecondaryCta: false,
    hideTimeRemaining: true,
  },
} as const

export const resolveSiteAssets = (
  assets: ISiteAssetsFields,
  domain: Domain = 'invest.angel.com'
): SiteAssets => {
  return {
    ...assets,
    ...extraConfig[domain],
    domain,
    isWhiteLabel: whiteLabelUtil.isWhiteLabel(domain),
  }
}
