import React, { useContext } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Popover, Button } from '@/components/common'
import { AngelFundingLogo, Profile } from '@/components/svg'
import UserMenu from '../UserMenu'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { BellNotificationPopover } from '@/components/notifications/BellNotifications'
import useAuth from '@/services/hooks/useAuth'
import Image from '@/components/contentful/Image'
import SiteAssetsContext from '@/components/context/SiteAssetsContext'

export const Header = (): JSX.Element => {
  const { user, openModal } = useAuth()
  const router = useRouter()
  const siteAssets = useContext(SiteAssetsContext)

  return (
    <header id="header__main">
      <div className="relative w-full flex flex-col bg-gray-9 text-black justify-center items-start font-whitney z-[1000]">
        <div className="flex w-full justify-center items-center h-[56px] md:h-[72px]">
          <Link href={'/'}>
            <a
              aria-label="angel-funding-logo"
              className={classNames({
                'md:absolute md:left-4': true,
              })}
            >
              {siteAssets && (
                <h1 className="text-[0]">
                  {siteAssets.title || 'Angel Funding'}
                </h1>
              )}
              {siteAssets && siteAssets.logo && (
                <Image
                  image={siteAssets.logo}
                  height={32}
                  className="h-[26px] md:h-[32px]"
                />
              )}
              {siteAssets && !siteAssets.logo && (
                <AngelFundingLogo className="h-[26px] md:h-[32px] fill-black" />
              )}
            </a>
          </Link>

          {user && (
            <div className="absolute right-4 flex items-center">
              <BellNotificationPopover />

              <Popover
                content={
                  <UserMenu.LoggedIn className="flex flex-col w-[280px] p-3" />
                }
                placement="bottom"
                label="User Menu"
                theme="light"
              >
                <Profile className="w-7 h-7" />
              </Popover>
            </div>
          )}

          {/* Explicitly check that auth is null to avoid SSR/hydration issues */}
          {user === null && (
            <div className="absolute flex items-center right-4">
              <Popover
                content={
                  <UserMenu.LoggedOut className="flex flex-col w-full p-3" />
                }
                placement="bottom"
                label="Login or Sign Up"
                className="md:hidden"
                theme="light"
              >
                <Profile className="w-7 h-7" />
              </Popover>

              <div className="hidden md:block">
                <Button
                  variant="text"
                  size="sm"
                  color="black"
                  onClick={() => {
                    openModal('login')

                    SegmentHandler.track('On Tap', {
                      location: router.asPath,
                      section_name: 'header',
                      element_name: 'menu_item',
                      value: 'Login',
                      action_type: 'tap',
                    })
                  }}
                >
                  Log In
                </Button>

                <Button
                  variant="text"
                  size="sm"
                  color="black"
                  className="ml-1"
                  onClick={() => {
                    openModal('register')

                    SegmentHandler.track('On Tap', {
                      location: router.asPath,
                      section_name: 'header',
                      element_name: 'menu_item',
                      value: 'Sign Up',
                      action_type: 'tap',
                    })
                  }}
                >
                  Sign Up
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
