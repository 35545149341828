import SegmentHandler from '../analytics/SegmentHandler'
import client from './thinMintClient'

export const trackSegmentEvent = async (event: string, properties: any) => {
  const context = {
    page: {
      url: window?.location?.href,
    },
    userAgent: navigator?.userAgent,
  }
  const { anonymousId, userId } = SegmentHandler.getState().user
  const params = {
    userId: userId || anonymousId, // userId required for TM
    anonymousId,
    context,
    event,
    properties,
  }

  return client.post('/segment/track', {
    ...params,
    anonymous_id: params.anonymousId,
    user_id: params.userId,
  })
}
