import React from 'react'
import ContentLoader from 'react-content-loader'

const ImageLoader = ({ widthRatio = 16, heightRatio = 9 }) => (
  <ContentLoader
    speed={2}
    viewBox={`0 0 ${widthRatio} ${heightRatio}`}
    backgroundColor="#E0E0E0"
    foregroundColor="#ecebeb"
    className="hidden md:block"
  >
    <rect x="0" y="0" width={widthRatio} height={heightRatio} />
  </ContentLoader>
)

export default ImageLoader
