import axios from 'axios'

const apiClient = axios.create({
  baseURL:
    process.env.NEXT_PUBLIC_ELLIS_ISLAND_URL ||
    'https://auth.stg.angelstudios.com',
})

// caches get requests
const get = apiClient.get
apiClient.get = async (url, params, axiosConfig) => {
  const response = await get(url, params, axiosConfig)
  return response
}

const post = apiClient.post
apiClient.post = async (url, data, axiosConfig, log = false) => {
  const response = await post(url, data, axiosConfig)
  return response
}

const patch = apiClient.patch
apiClient.patch = async (url, data, log = false) => {
  const response = await patch(url, data)
  return response
}

const put = apiClient.put
apiClient.put = async (url, data, axiosConfig, log = false) => {
  const response = await put(url, data, axiosConfig)
  return response
}

export default apiClient
