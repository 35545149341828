import client from '../thinMintClient'
import { resolveIVA, resolveIVAPayload } from '../resolvers'

export interface IVAErrors {
  requireAddress: boolean
  requireDob: boolean
  requireDriversLicense: boolean
  requireName: boolean
  requireSsn: boolean
  requireOwnerInfo: boolean
  requireProofOfAddress: boolean
  requireProofOfEin: boolean
  requireProofOfSsn: boolean
}

export interface IVA {
  id: number
  partyId: number
  entityId: number
  investmentId: string
  customFeedback?: string
  errors: IVAErrors
  status: 'Pending Feedback' | 'Pending Review' | 'Pending User Action'
}

export interface IVAPayload {
  auto_recheck: true
  custom_feedback: string
  feedback_issued_at: string
  investment: string
  party: number
  entity: number
  portal_reviewed_at: boolean | null
  require_address: boolean
  require_dob: boolean
  require_drivers_license: boolean
  require_name: boolean
  require_owner_info: boolean
  require_proof_of_address: boolean
  require_proof_of_ein: boolean
  require_proof_of_ssn: boolean
  require_ssn: boolean
  status: string
  user_completed_at: string | null
}

export interface IVAForm {
  firstName?: string
  lastName?: string
  middleInitial?: string
  dateOfBirth?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  ssn?: string
  driversLicense?: string
  ownerInfo?: File
  proofOfAddress?: File
  proofOfEin?: File
  proofOfSsn?: File
}

export const getUserIVAs = () => ({
  key: 'user-ivas/',
  request: () => client.get(`iva/`),
  resolve: (res: { data: IVAPayload[] }): IVA[] => res.data.map(resolveIVA),
})

// TODO: should prob be renamed getInvestmentIVAs
export const getIVA = (investmentId: string) => ({
  key: `investment/${investmentId}/owned-open-ivas/`,
  request: () => client.get(`investments/${investmentId}/owned-open-ivas/`),
  resolve: (res: { data: IVAPayload[] }): IVA[] => res.data.map(resolveIVA),
})

export const getCampaignIVA = (slug: string) => ({
  key: `iva/?campaign_slug=${slug}`,
  request: () => client.get(`iva/?campaign_slug=${slug}`),
  resolve: (res: { data: IVAPayload[] }) => res.data.map(resolveIVA),
})

export const updatePartyIVA = (partyId: number, ivaId: number) => ({
  request: (form: IVAForm) =>
    client.patch(`parties/${partyId}/iva/${ivaId}/`, resolveIVAPayload(form)),
})

export const updateEntityIVA = (entityId: number, ivaId: number) => ({
  request: (form: IVAForm) =>
    client.patch(`entities/${entityId}/iva/${ivaId}/`, resolveIVAPayload(form)),
})

export default {
  getUserIVAs,
  getIVA,
  getCampaignIVA,
  updatePartyIVA,
  updateEntityIVA,
}
