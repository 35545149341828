import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Text, ButtonBase } from '@/components/common'
import { ToastNotification as Notification } from '@/components/context/NotificationsContext/NotificationsContext'
import { Exclamation, Close } from '@/components/svg'

export interface Props extends Omit<Notification, 'type' | 'id'> {
  handleRemove: () => void
}

export const ToastNotification: React.FC<Props> = ({
  variant,
  text,
  actions = [],
  dismissable = true,
  timeout,
  handleRemove,
}) => {
  useEffect(() => {
    let timeoutInstance: ReturnType<typeof setTimeout>
    if (timeout) {
      timeoutInstance = setTimeout(() => {
        handleRemove()
      }, timeout)
    }

    return () => {
      if (timeoutInstance) {
        clearTimeout(timeoutInstance)
      }
    }
  }, [timeout, handleRemove])

  return (
    <div className="bg-gray-1 flex items-center mb-4 px-2 py-3 rounded-sm max-w-2xl">
      <Exclamation
        className={classNames({
          'w-6 h-6 mr-2 flex-shrink-0': true,
          'text-oxide-3': variant === 'warning',
          'text-red': variant === 'error',
        })}
      />
      <Text as="p" preset="body.md" className="text-white font-light">
        {text}
      </Text>

      <div className="inline-flex items-center pl-2 ml-auto">
        {actions.map(
          ({ label, onClick, variant: actionVariant = 'primary' }, idx) => (
            <ButtonBase
              key={idx}
              onClick={() => {
                onClick()
                handleRemove()
              }}
              className={classNames({
                'my-1 sm:my-0 sm:ml-2 pl-2 sm:border-l border-gray-5 first:border-l-0 whitespace-nowrap leading-none':
                  true,
                'text-oxide-3 font-semibold':
                  actionVariant === 'primary' && variant === 'warning',
                'text-red font-semibold':
                  actionVariant === 'primary' && variant === 'error',
                'text-gray-8 font-light': actionVariant === 'secondary',
              })}
            >
              <Text as="span" preset="body.md">
                {label}
              </Text>
            </ButtonBase>
          )
        )}

        {dismissable && (
          <ButtonBase
            onClick={handleRemove}
            className="my-1 sm:my-0 sm:ml-2 pl-2 sm:border-l border-gray-5 first:border-l-0"
            aria-label="Close notification"
          >
            <Close className="w-5 h-5 text-white" />
          </ButtonBase>
        )}
      </div>
    </div>
  )
}

export default ToastNotification
