import React from 'react'
import { Popover as UIPopover } from '@headlessui/react'
import { useRouter } from 'next/router'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { Text, Button } from '@/components/common'
import useAuth from '@/services/hooks/useAuth'

export interface Props {
  className?: string
}

const UserMenuLoggedOut: React.FC<Props> = ({ className }) => {
  const { openModal } = useAuth()
  const router = useRouter()

  return (
    <div className={className}>
      <UIPopover.Button as="div">
        <Button
          size="sm"
          className="w-[150px]"
          onClick={() => {
            openModal('login')

            SegmentHandler.track('On Tap', {
              location: router.asPath,
              section_name: 'header',
              element_name: 'menu_item',
              value: 'Login',
              action_type: 'tap',
            })
          }}
        >
          <Text as="span" preset="body.md">
            Log In
          </Text>
        </Button>
      </UIPopover.Button>

      <UIPopover.Button as="div">
        <Button
          variant="secondary"
          size="sm"
          className="w-full mt-2"
          onClick={() => {
            openModal('register')

            SegmentHandler.track('On Tap', {
              location: router.asPath,
              section_name: 'header',
              element_name: 'menu_item',
              value: 'Sign Up',
              action_type: 'tap',
            })
          }}
        >
          <Text as="span" preset="body.md">
            Sign Up
          </Text>
        </Button>
      </UIPopover.Button>
    </div>
  )
}

export default UserMenuLoggedOut
