import authClient from './authClient'
import thinMintClient from './thinMintClient'
import { v4 as uuidv4 } from 'uuid'

const endpoint = '/auth/login'

export const emailLogin = (password, username) => {
  const dataJson = { password, username, source: 'invest' }
  return authClient.post(endpoint, dataJson, false)
}

export const facebookLogin = (state, token) =>
  authClient.get('auth/facebook', {
    params: {
      state,
      token,
      source: 'invest',
      anon_stats_id: uuidv4(),
    },
  })

export const googleLogin = (state, code) =>
  authClient.get('auth/google', {
    params: {
      state,
      code,
      source: 'invest',
      anon_stats_id: uuidv4(),
      redirect_uri: `${location.protocol}//${location.host}`,
    },
  })

export const sendEmailVerification = (email) => {
  const dataJson = { email }
  return thinMintClient.post('/users/email-verification/', dataJson, false)
}

export const resetPasswordEmail = (email) => {
  const dataJson = { email }
  return thinMintClient.post('/password/request/', dataJson, false)
}

export const register = (email, password, verification_code) => {
  const dataJson = {
    agreed_to_investor_terms: true,
    auto_generated: false,
    email,
    password,
    verification_code,
    welcome_email: 'portal',
    signup_location: 'studios',
  }
  return thinMintClient.post('/users/', dataJson, false)
}

export const resetPassword = (password, password_confirm, validation_key) => {
  const dataJson = { password, password_confirm, validation_key }
  return thinMintClient.post('/password/reset/', dataJson, false)
}

export default {
  emailLogin,
  facebookLogin,
  googleLogin,
  register,
  sendEmailVerification,
  resetPasswordEmail,
  resetPassword,
}
